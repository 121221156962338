import { Drawer } from '@mui/material';
import useDrawer from 'hooks/useDrawer';
import { IDrawer } from 'interfaces/reducer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideDrawer, showDrawer } from 'services/reducer/drawer';

const check: boolean[] = [];

const DrawerList = () => {
  const dispatch = useDispatch();
  const { drawers } = useDrawer();

  const handleClose = async () => {
    dispatch(hideDrawer());
  };

  const list = () => {
    const rows: React.ReactElement[] = [];

    drawers.forEach((value: IDrawer, i: number) => {
      setTimeout(() => {
        if (!check[i]) {
          dispatch(showDrawer());
          check[i] = true;
        }
      }, 50);

      rows.push(
        <Drawer
          key={i}
          PaperProps={{ sx: { background: value.bg }, style: { width: value.width } }}
          anchor={value.anchor}
          open={value.open}
          onClose={() => handleClose()}
        >
          {value.content}
        </Drawer>,
      );
    });

    return rows;
  };

  useEffect(() => {
    if (drawers[0]?.open) {
      document.body.classList.add('hasDrawer');
    } else {
      document.body.classList.remove('hasDrawer');
    }
  }, [drawers]);

  return <div className='drawerParent'>{list()}</div>;
};

export default DrawerList;
