import PatchCode from 'components/Helper/PatchCode';
import config from 'config';
import useTimer from 'hooks/useTimer';
import { IVerify } from 'interfaces/email';
import { IDispatch } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import gather from 'utils/gather';

const Verify = ({ setStep, email }: IVerify) => {
  const [code, setCode] = useState<number[]>([]);
  const dispatch = useDispatch<IDispatch>();
  const timer = useTimer();

  const confirmAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/email/verify`, true).post({
      code: code.join(''),
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      await dispatch(getProfile());
      dispatch(successAlert('ایمیل شما با موفقیت ثبت شد'));
      dispatch(hideDrawer());
    } else {
      setCode([]);
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  useEffect(() => {
    timer.start();
  }, []);

  useEffect(() => {
    if (code.join('').match(/[0-9]{6}/)) {
      confirmAction();
    }
  }, [code]);

  return (
    <>
      <h4 className='confirmTitle confirmTitleIn'>کد تایید ایمیل جدید</h4>
      <div className='rules'>
        کد تایید برای ایمیل<i>{email}</i>ارسال شد
      </div>
      <PatchCode code={code} setCode={setCode} />
      <div className='changeMobile'>
        <i>
          {!timer.isFinish ? (
            <>
              {timer.minute ? `${timer.minute} دقیقه و ` : ''}
              {timer.second} ثانیه
            </>
          ) : (
            <div
              className='active'
              onClick={() => {
                // startTimer();
              }}
            >
              ارسال مجدد کد
            </div>
          )}
        </i>
        <p onClick={() => setStep(1)}>ویرایش ایمیل</p>
      </div>
      <div className='spacer'></div>
      <div className='largeButton' onClick={() => confirmAction()}>
        تایید و ادامه
      </div>
    </>
  );
};

export default Verify;
