import Alarms from 'components/Modules/Alarms';
import Notices from 'components/Modules/Notices';
import config from 'config';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { centerDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import gather from 'utils/gather';
import { isUserLogin } from 'utils/user';
import Menu from './Menu';
import PreLogin from './PreLogin';

let READY = 0;

const Header = ({ title }: { title: string }) => {
  const dispatch = useDispatch();
  const [alarm, setAlarm] = useState(READY);

  const init = async () => {
    const result = await gather(`${config.data.api}/v1/alarm`, true).get();
    if (result.code === 200) {
      READY = result.data.filter((e: any) => !e.isRead).length;
      setAlarm(READY);
    }
  };

  useEffect(() => {
    if (READY === 0 && isUserLogin()) {
      init();
    }
  }, []);

  return (
    <div className='top topHeader'>
      <div className='container'>
        <div className='menuIcon' onClick={() => dispatch(fullDrawer(<Menu />))}></div>
        <h1>{title}</h1>
        <div
          className={`menuIcon noticeIcon`}
          onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : dispatch(fullDrawer(<Notices />)))}
        ></div>
        <div
          className={`menuIcon bellIcon bellColor_${alarm}`}
          onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : dispatch(fullDrawer(<Alarms />)))}
        >
          <span>{alarm}</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
