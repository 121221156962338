import Top from 'components/Layouts/Top';
import Uploader from 'components/Layouts/Uploader';
import config from 'config';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const NewTicket = () => {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [src, setSrc] = useState('');
  const dispatch = useDispatch();

  const saveAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/ticket`, true).post({
      title: title,
      text: text,
      subject: 'support',
      file: src,
    });

    if (result.code === 200) {
      dispatch(successAlert('درخواست با موفقیت ثبت شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'ثبت تیکت جدید'} />
        <div className='container inData'>
          <div className='mainInput'>
            <p>عنوان</p>
            <div>
              <span className='name'></span>
              <input type='text' className='rtl' onChange={(e) => setTitle(e.target.value)} />
            </div>
          </div>
          <div className='mainInput'>
            <p>توضیح</p>
            <div>
              <span className='id'></span>
              <textarea className='rtl' onChange={(e) => setText(e.target.value)} />
            </div>
          </div>
          <Uploader src={src} setSrc={setSrc} />
          <div className='spacer'></div>
          <div className='largeButton' onClick={() => saveAction()}>
            ثبت تیکت
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewTicket;
