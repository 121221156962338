import Top from 'components/Layouts/Top';
import useUser from 'hooks/useUser';
import { useState } from 'react';
import Card from './Card';
import Confirm from './Confirm';
import Form from './Form';

const stepsTitles = ['مشخصات فردی', 'افزودن کارت', 'تایید درخواست'];

const Identity = () => {
  const { profile } = useUser();
  const [step, setStep] = useState(!profile.verify.identity ? 1 : !profile.verify.card ? 2 : 3);

  const stepsRender = () => {
    const rows: React.ReactElement[] = [];

    stepsTitles.forEach((e, i) => {
      rows.push(
        <div key={i} className={i + 1 <= step ? 'active' : ''}>
          <span></span>
          {e}
        </div>,
        <b key={i + 10}></b>,
      );
    });

    return rows;
  };

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'احراز هویت'} />
        <div className='allSteps'>{stepsRender()}</div>
        <div className='container inData'>
          {step === 1 ? <Form setStep={setStep} /> : step === 2 ? <Card setStep={setStep} /> : <Confirm />}
        </div>
      </div>
    </div>
  );
};

export default Identity;
