import OptionForm from 'components/Helper/OptionForm';
import Coins from 'components/Layouts/Coins';
import Top from 'components/Layouts/Top';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import { ICoin, IDispatch } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { centerDialog } from 'services/reducer/dialog';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Address = ({ setData }: { setData: any }) => {
  const global = useGlobal();
  const [receiver, setReceiver] = useState('');
  const [title, setTitle] = useState('');
  const [memo, setMemo] = useState('');
  const [coin, setCoin] = useState<ICoin | null>(null);
  const [network, setNetwork] = useState('');
  const [list, setList] = useState([]);
  const dispatch = useDispatch<IDispatch>();

  const listRender = () => {
    const rows: JSX.Element[] = [];

    list
      .sort((a: any, b: any) => b.updatedAt - a.updatedAt)
      .forEach((e: any) => {
        rows.push(
          <div
            onClick={() => {
              setData(e);
              dispatch(hideDrawer());
            }}
          >
            <span>{e.title}</span>
            <span>{e.receiver}</span>
            <span
              className='cancelOrder'
              onClick={(o) => {
                o.stopPropagation();
                deleteAddress(e._id);
              }}
            ></span>
          </div>,
        );
      });

    if (rows.length === 0) {
      return <section className='noItem'>هیچ آدرس ثبت شده ای ندارید</section>;
    }

    return rows;
  };

  const deleteAddress = async (id: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/address/${id}`, true).delete();
    if (result.code === 200) {
      await init();
      dispatch(successAlert('درخواست با موفقیت ثبت شد'));
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  const saveAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/address`, true).post({
      coin: coin?._id,
      network: coin?.networks.find((e) => e.network.name === network)?.network._id,
      title: title,
      receiver: receiver,
      memo: memo,
    });

    if (result.code === 200) {
      setData(result.data);
      dispatch(successAlert('درخواست با موفقیت ثبت شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/address`, true).get();
    if (result.code === 200) {
      setList(result.data);
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setNetwork('');
  }, [coin]);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'آدرس های منتخب'} />
        <div className='container inData'>
          <div className='listOfAddress'>{listRender()}</div>
          <div className='mainInput mainInputAddress'>
            <div>
              <input type='text' placeholder='عنوان دلخواه' onChange={(e) => setTitle(e.target.value)} />
            </div>
            <div className='noInput' onClick={() => dispatch(centerDialog(<Coins setCoin={setCoin} />))}>
              {coin ? (
                <>
                  <img crossOrigin='anonymous' src={coin.icon} />
                  <h3>
                    {coin.name} ({coin.symbol.toUpperCase()})
                  </h3>
                </>
              ) : (
                <>انتخاب ارز</>
              )}
            </div>
            <div
              className='noInput'
              onClick={() =>
                coin &&
                dispatch(
                  centerDialog(<OptionForm setOption={setNetwork} value={coin?.networks.reduce((a, v) => ({ ...a, [v.network.name]: v.network.name }), {})} />),
                )
              }
            >
              {network ? <h3>{network}</h3> : <>نوع شبکه</>}
            </div>
            <div>
              <input type='text' placeholder='آدرس کیف پول' onChange={(e) => setReceiver(e.target.value)} />
            </div>
            <div>
              <input type='text' placeholder='MEMO/TAG (اختیاری)' onChange={(e) => setMemo(e.target.value)} />
            </div>
          </div>
          <div className='largeButton' onClick={() => saveAction()}>
            ثبت آدرس جدید
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
