import useGlobal from 'hooks/useGlobal';
import moment from 'jalali-moment';
import { statusColor, typeColor } from 'utils/color';
import { autoFormatter } from 'utils/numeral';
import { sideTranslate, statusTranslate, tradeTranslate } from 'utils/translate';

const Order = ({ data }: any) => {
  const global = useGlobal();

  return (
    <div className='voucherData'>
      <div className='listCashCode listCashCodeCustom'>
        <section>
          <div>
            <span>نوع</span>
            <span className={`color${typeColor(data.side)}`}>{sideTranslate(data.side)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>ارز</span>
            <span className='currencyDetail'>
              {data.coin.name} ({data.coin.symbol.toUpperCase()})
              <img crossOrigin='anonymous' src={global.coins[data.coin.symbol].icon} />
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>وضعیت</span>
            <span className={`boxStatus bg${statusColor(data.status)}`}>{statusTranslate(data.status)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>بازار</span>
            <span>{tradeTranslate(data.trade)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>قیمت</span>
            <span className='ltr'>
              {autoFormatter(data.price)} {data.pair.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>مقدار</span>
            <span className='ltr'>
              {autoFormatter(data.amount)} {data.coin.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>کارمزد</span>
            <span className='ltr'>
              {autoFormatter(data.fee.amount)} {data.side === 'sell' ? data.pair.toUpperCase() : data.coin.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>نهایی</span>
            <span className='ltr'>
              {autoFormatter(data.final)} {data.side === 'sell' ? data.pair.toUpperCase() : data.coin.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>تاریخ</span>
            <span className='ltr'>{moment(data.createdAt).locale('fa').format('YYYY-MM-DD , HH:mm:ss')}</span>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Order;
