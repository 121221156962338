import Tabs from 'components/Helper/Tabs';
import Top from 'components/Layouts/Top';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { centerDialog } from 'services/reducer/dialog';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { statusColor, typeColor } from 'utils/color';
import gather from 'utils/gather';
import { autoFormatter } from 'utils/numeral';
import { sideTranslate, statusTranslate } from 'utils/translate';
import Detail from '../Detail';

const tabTitles = {
  order: 'سفارشات',
  paymentDeposit: 'واریز تومان',
  paymentWithdraw: 'برداشت تومان',
  deposit: 'واریز رمزارز',
  withdraw: 'برداشت رمزارز',
};

const History = () => {
  const global = useGlobal();
  const [tab, setTab] = useState(tabTitles.order);
  const [list, setList] = useState([]);
  const [from, _setFrom] = useState(
    Math.floor(
      moment()
        .subtract(360, 'd')
        .valueOf(),
    ),
  );
  const [to, _setTo] = useState(
    Math.floor(
      moment()
        .add(10, 'm')
        .valueOf(),
    ),
  );

  const dispatch = useDispatch();

  const ordersRender = () => {
    const rows: JSX.Element[] = [];

    list
      .filter((e: any) => e.tab === tab)
      .forEach((e: any) => {
        rows.push(
          tabTitles.order === tab ? (
            <div onClick={() => dispatch(centerDialog(<Detail data={e} type='order' />))}>
              <section>
                <img crossOrigin='anonymous' src={global.coins[e.coin.symbol].icon} />
                <span className='f1'>{e.coin.symbol.toUpperCase()}</span>
                <span>
                  {moment(e.createdAt)
                    .locale('fa')
                    .format('YYYY-MM-DD , HH:mm:ss')}
                </span>
              </section>
              <section>
                <span className={`color${typeColor(e.side)}`}>{sideTranslate(e.side)}</span>
                <span className={`boxStatus bg${statusColor(e.status)}`}>{statusTranslate(e.status)}</span>
              </section>
              <section>
                <span>مقدار</span>
                <span className='ltr'>{autoFormatter(e.amount)}</span>
              </section>
              <section>
                <span>
                  قیمت <b>({e.pair.toUpperCase()})</b>
                </span>
                <span className='ltr'>{autoFormatter(e.price)}</span>
              </section>
            </div>
          ) : tabTitles.paymentWithdraw === tab || tabTitles.paymentDeposit === tab ? (
            <div onClick={() => dispatch(centerDialog(<Detail data={e} type='payment' />))}>
              <section>
                <img crossOrigin='anonymous' src='./images/tmn.png' />
                <span>تومان</span>
                <span>
                  {moment(e.createdAt)
                    .locale('fa')
                    .format('YYYY-MM-DD , HH:mm:ss')}
                </span>
              </section>
              <section>
                <span className={`color${typeColor(e.side)}`}>{sideTranslate(e.side)}</span>
                <span className={`boxStatus bg${statusColor(e.status)}`}>{statusTranslate(e.status)}</span>
              </section>
              <section>
                <span>مقدار</span>
                <span>{autoFormatter(e.amount)} تومان</span>
              </section>
            </div>
          ) : tabTitles.withdraw === tab || tabTitles.deposit === tab ? (
            <div onClick={() => dispatch(centerDialog(tabTitles.withdraw === tab ? <Detail data={e} type='withdraw' /> : <Detail data={e} type='deposit' />))}>
              <section>
                <img crossOrigin='anonymous' src={global.coins[e.coin.symbol].icon} />
                <span className='f1'>{e.coin.symbol.toUpperCase()}</span>
                <span>
                  {moment(e.createdAt)
                    .locale('fa')
                    .format('YYYY-MM-DD , HH:mm:ss')}
                </span>
              </section>
              <section>
                <span>{tabTitles.deposit === tab ? 'واریز رمزارز' : 'برداشت رمزارز'}</span>
                <span className={`boxStatus bg${statusColor(e.status)}`}>{statusTranslate(e.status)}</span>
              </section>
              <section>
                <span>مقدار</span>
                <span className='ltr'>{autoFormatter(e.final)}</span>
              </section>
            </div>
          ) : (
            <></>
          ),
        );
      });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return rows;
  };

  const getData = async (address: string) => {
    const result = await gather(`${config.data.api}/v1/${address}?fromDate=${from}&toDate=${to}`, true).get();
    if (result.code === 200) {
      return result.data.map((e: any) =>
        Object.assign(
          { tab: address === 'payment' ? (tabTitles as any)[e.side === 'cashout' ? 'paymentWithdraw' : 'paymentDeposit'] : (tabTitles as any)[address] },
          e,
        ),
      );
    }
    return [];
  };

  const init = async () => {
    dispatch(showLoading());

    setList([]);

    const result = await Promise.all([getData('withdraw'), getData('deposit'), getData('payment'), getData('order')]).then((results) => {
      return [].concat.apply([], [results[0], results[1], results[2], results[3]]);
    });

    setList(result.sort((b: any, a: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'تاریخچه تراکنش ها'} />
        <div className='container inData'>
          <div className='tabs tabsTop'>
            <Tabs titles={tabTitles} selected={tab} setSelected={setTab} />
          </div>
          <div className='historyDetail'>{ordersRender()}</div>
        </div>
      </div>
    </div>
  );
};

export default History;
