import Identity from 'components/Modules/Identity';
import Level1 from 'components/Modules/Level1';
import Level2 from 'components/Modules/Level2';
import config from 'config';
import useUser from 'hooks/useUser';
import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { formatter } from 'utils/numeral';
import Top from './Top';

const LevelList = () => {
  const dispatch = useDispatch();
  const { profile } = useUser();
  const [list, setList] = useState([]);

  const listRender = () => {
    const rows: JSX.Element[] = [];

    list
      .sort((a: any, b: any) => a.index - b.index)
      .forEach((e: any) => {
        rows.push(
          <div className='levelView'>
            <h3 style={{ background: e.color }}>
              <i>سطح {e.name}</i> {profile.plane.level.index >= e.index && <p style={{ color: e.color }}>تایید شده</p>}
            </h3>
            {profile.plane.level.index + 1 === e.index && (
              <>
                <div>
                  <h6>اطلاعات مورد نیاز</h6>
                  <section>
                    <span>ثبت شماره تلفن همراه</span>
                    <article></article>
                    <span>ثبت و بارگزاری اطلاعات هویتی</span>
                  </section>
                </div>
                <div>
                  <h6>امکانات</h6>
                  <div>
                    <span></span>
                    <span>واریز تومانی روزانه</span>
                    <span>نامحدود</span>
                  </div>
                  <div>
                    <span></span>
                    <span>برداشت تومانی روزانه</span>
                    <span>{formatter(e.withdraw.fiat.daily)}</span>
                  </div>
                  <div>
                    <span></span>
                    <span>واریز دلاری روزانه</span>
                    <span>نامحدود</span>
                  </div>
                  <div>
                    <span></span>
                    <span>برداشت دلاری روزانه</span>
                    <span>{formatter(e.withdraw.coin.daily)}</span>
                  </div>

                  <div>
                    <span></span>
                    <span>واریز تومانی ماهانه</span>
                    <span>نامحدود</span>
                  </div>
                  <div>
                    <span></span>
                    <span>برداشت تومانی ماهانه</span>
                    <span>{formatter(e.withdraw.fiat.monthly)}</span>
                  </div>
                  <div>
                    <span></span>
                    <span>واریز دلاری ماهانه</span>
                    <span>نامحدود</span>
                  </div>
                  <div>
                    <span></span>
                    <span>برداشت دلاری ماهانه</span>
                    <span>{formatter(e.withdraw.coin.monthly)}</span>
                  </div>
                </div>
                <div>
                  <h6>کارمزد ها</h6>
                  <div>
                    <span></span>
                    <span className='colorRed'>کارمزد خرید</span>
                    <span>{e.fee.buy} درصد</span>
                  </div>
                  <div>
                    <span></span>
                    <span className='colorGreen'>کارمزد فروش</span>
                    <span>{e.fee.sell} درصد</span>
                  </div>
                </div>
                {profile.plane.request === 'reject' && profile.plane.nextTime > moment().unix() ? (
                  <h5 className='largeButton largeButtonLevel' style={{ background: 'red' }}>
                    علت رد: {profile.plane.reason}
                    <br />
                    زمان بعدی درخواست:{' '}
                    {moment(profile.plane.nextTime)
                      .locale('fa')
                      .format('YYYY-MM-DD ساعت HH:mm')}
                  </h5>
                ) : profile.plane.request === 'pending' ? (
                  <h5 className='largeButton largeButtonLevel' style={{ background: '#9f9f9f' }}>
                    در انتظار بررسی مدیریت
                  </h5>
                ) : (
                  <h5
                    className='largeButton largeButtonLevel'
                    style={{ background: e.color }}
                    onClick={() => {
                      dispatch(hideDrawer());
                      setTimeout(() => {
                        dispatch(fullDrawer(e.index === 1 ? <Identity /> : e.index === 2 ? <Level1 /> : <Level2 />));
                      }, 300);
                    }}
                  >
                    ارتقا به سطح کاربری {e.index}
                  </h5>
                )}
              </>
            )}
          </div>,
        );
      });

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/level`).get();
    if (result.code === 200) {
      setList(result.data);
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm registerFormBottom'>
        <Top title={'سطح کاربری'} />
        <div className='container'>{listRender()}</div>
      </div>
    </div>
  );
};

export default LevelList;
