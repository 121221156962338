import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import { IBalance, ICard, IProfile, IResponse } from 'interfaces/global';
import gather from 'utils/gather';

const initialState = {
  balance: {
    asset: {} as { [k: string]: IBalance },
    fiat: {} as IBalance,
  },
  profile: {} as IProfile,
  favorite: [] as string[],
  cards: {} as { [k: string]: ICard },
};

const getCards = createAsyncThunk('list/fetchList', async () => {
  return await gather(`${config.data.api}/v1/card`, true).get();
});

const addCard = createAsyncThunk(
  'add/fetchAdd',
  async (data: any): Promise<IResponse> => {
    return await gather(`${config.data.api}/v1/card`, true).post(data);
  },
);

const getAccountBalance = createAsyncThunk('accountBalance/fetchAccountBalance', async () => {
  return await gather(`${config.data.api}/v1/balance`, true).get();
});

const getProfile = createAsyncThunk('profile/fetchProfile', async () => {
  return await gather(`${config.data.api}/v1/user`, true).get();
});

const getFavorite = createAsyncThunk('favorite/fetchProfile', async () => {
  return await gather(`${config.data.api}/v1/favorite`, true).get();
});

const getWalletBalance = createAsyncThunk('walletBalance/fetchWalletBalance', async () => {
  return await gather(`${config.data.api}/v1/asset`, true).get();
});

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updateBalance: (state, action) => {
      state.balance.fiat = action.payload;
    },
    updateAsset: (state, action) => {
      state.balance.asset[action.payload.coin] = action.payload;
    },
    updateProfile: (state, action) => {
      if (action.payload.nationalCode) {
        state.profile.nationalCode = action.payload.nationalCode;
      }
      if (action.payload.birthDate) {
        state.profile.birthDate = action.payload.birthDate;
      }
    },
    removeCard: (state, actions) => {
      delete state.cards[actions.payload];

      gather(`${config.data.api}/v1/card`, true).delete({
        card: actions.payload,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCards.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: ICard) => {
            state.cards[value.number!] = value;
          });
        }
      })
      .addCase(addCard.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.cards[action.meta.arg.number] = action.meta.arg;
        }
      })
      .addCase(getWalletBalance.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: any) => {
            state.balance.asset[value.coin.symbol] = value;
          });
        }
      })
      .addCase(getAccountBalance.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.balance.fiat = action.payload.data;
        }
      })
      .addCase(getFavorite.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.favorite = action.payload.data;
        }
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.profile = action.payload.data;
        }
      });
  },
});

export { addCard, getAccountBalance, getCards, getFavorite, getProfile, getWalletBalance };
export const { updateAsset, updateBalance, updateProfile } = slice.actions;
export default slice.reducer;
