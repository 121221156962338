import useUser from 'hooks/useUser';
import bank from 'utils/bank';

const View = ({ data }: any) => {
  const user = useUser();

  return (
    <div className='popup viewCard'>
      <div className={!data.bank ? 'noBank' : ''}>
        {data.bank && <img src={bank.detect(data.number)?.logo || './svg/cardDown.svg'} />}
        <span>
          {data.bank && <img src={bank.detect(data.number)?.logo || './svg/cardDown.svg'} />}
          <b>{data.number.match(new RegExp('.{1,4}', 'g'))?.join('-')}</b>
          <i>{data?.iban ?? '-----------'}</i>
          <section>
            <h1>
              {user.profile.name} {user.profile.family}
            </h1>
            <h2>{bank.detect(data.number)?.title || '----'}</h2>
          </section>
        </span>
      </div>
    </div>
  );
};

export default View;
