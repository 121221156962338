import useGlobal from 'hooks/useGlobal';
import moment from 'jalali-moment';
import { useDispatch } from 'react-redux';
import { successAlert } from 'services/reducer/alert';
import { statusColor } from 'utils/color';
import { dotted } from 'utils/tools';
import { statusTranslate } from 'utils/translate';

const Deposit = ({ data }: any) => {
  const global = useGlobal();
  const dispatch = useDispatch();

  const copy = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch(successAlert('کپی شد'));
  };

  return (
    <div className='voucherData'>
      <div className='listCashCode listCashCodeCustom'>
        <section>
          <div>
            <span>وضعیت</span>
            <span className={`color${statusColor(data.status)}`}>{statusTranslate(data.status)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>ارز</span>
            <span className='currencyDetail'>
              {data.coin.name} ({data.coin.symbol.toUpperCase()})
              <img crossOrigin='anonymous' src={global.coins[data.coin.symbol].icon} />
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>مبلغ</span>
            <span className='ltr'>
              {data.amount} {data.coin.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>کارمزد</span>
            <span className='ltr'>
              {data.fee} {data.coin.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>نهایی</span>
            <span className='ltr'>
              {data.final} {data.coin.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>ارسال کننده</span>
            <i onClick={() => copy(data.sender)}></i>
            <span className='ltr'>{dotted(data.sender)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>رسید</span>
            <i onClick={() => copy(data.hash)}></i>
            <span className='ltr' onClick={() => window.open(data.network.scanner + '/' + data.hash, '_blank')?.focus()}>
              {dotted(data.hash)}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>تاریخ</span>
            <span className='ltr'>
              {moment(data.createdAt)
                .locale('fa')
                .format('YYYY-MM-DD , HH:mm:ss')}
            </span>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Deposit;
