import { ITabs } from 'interfaces/helper';

const Tabs = ({ titles, selected, setSelected }: ITabs): React.ReactElement => {
  const rows: React.ReactElement[] = [];

  Object.entries(titles).forEach(([key, value]) => {
    rows.push(
      <span key={key} className={value == selected ? 'active' : ''} onClick={() => setSelected(value)}>
        {value}
      </span>,
    );
  });

  return <>{rows}</>;
};

export default Tabs;
