import config from 'config';
import { ICode } from 'interfaces/password';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Code = ({ mobile }: ICode) => {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [passwordEye, setPasswordEye] = useState(false);
  const [repasswordEye, setRepasswordEye] = useState(false);
  const dispatch = useDispatch();

  const confirmAction = async () => {
    if (password !== repassword) {
      dispatch(errorAlert('رمز عبور و تکرار آن مشابه نمی باشد'));
      return;
    }

    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/auth/forgetPassword/confirm`).post({
      mobile: mobile.startsWith('0') ? mobile : '0' + mobile,
      code: code,
      newPassword: password,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert('رمز عبور با موفقیت تغییر کرد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  return (
    <>
      <div className='rules'>
        کد اعتبارسنجی برای شماره <i>{mobile}</i> پیامک شد
      </div>
      <div className='mainInput'>
        <p>کد تایید</p>
        <div>
          <span></span>
          <input type='code' onChange={(e) => setCode(e.target.value)} />
        </div>
      </div>
      <div className='mainInput'>
        <p>رمز عبور جدید</p>
        <div>
          <span className='password'></span>
          <input type={!passwordEye ? 'password' : 'text'} autoComplete='one-time-code' onChange={(e) => setPassword(e.target.value)} />
          <i className={`eye ${passwordEye && 'active'}`} onClick={() => setPasswordEye(!passwordEye)}></i>
        </div>
      </div>
      <div className='mainInput'>
        <p>تکرار رمز عبور قدیم</p>
        <div>
          <span className='password'></span>
          <input type={!repasswordEye ? 'password' : 'text'} autoComplete='one-time-code' onChange={(e) => setRepassword(e.target.value)} />
          <i className={`eye ${repasswordEye && 'active'}`} onClick={() => setRepasswordEye(!repasswordEye)}></i>
        </div>
      </div>
      <div className='largeButton' onClick={() => confirmAction()}>
        تغییر رمز عبور
      </div>
    </>
  );
};

export default Code;
