import PatchCode from 'components/Helper/PatchCode';
import SelectBox from 'components/Helper/SelectBox';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import useUser from 'hooks/useUser';
import { ICoin } from 'interfaces/global';
import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { autoFormatter } from 'utils/numeral';
import { dotted } from 'utils/tools';
import Address from './Address';

const Crypto = ({ symbol }: { symbol?: string }) => {
  const dispatch = useDispatch();
  const global = useGlobal();
  const user = useUser();
  const [step, setStep] = useState(0);
  const [type, setType] = useState('sms');
  const [code, setCode] = useState<number[]>([]);
  const [amount, setAmount] = useState(0);
  const [address, setAddress] = useState('');
  const [data, setData] = useState<any>();
  const [coin, setCoin] = useState<ICoin | null>(global.coins[symbol!] || null);
  const [network, setNetwork] = useState('');

  const saveAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/withdraw`, true).post({
      coin: coin?._id,
      network: coin?.networks.find((e) => e.network.name === network)?.network._id,
      receiver: address,
      amount: amount,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      setStep(2);
      setType(result.data.type);
      window.scrollTo(0, 0);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  const confirmAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/withdraw/confirm`, true).post({
      coin: coin?._id,
      network: coin?.networks.find((e) => e.network.name === network)?.network._id,
      receiver: address,
      amount: amount,
      code: code.join(''),
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert('درخواست با موفقیت ثبت شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  useEffect(() => {
    if (data) {
      setCoin(global.coins[data?.coin.symbol]);
      setAddress(data?.receiver);

      setTimeout(() => {
        setNetwork(global.coins[data?.coin.symbol]?.networks.find((e) => e.network._id === data.network._id)?.network?.name!);
      }, 10);
    }
  }, [data]);

  useEffect(() => {
    setNetwork('');
  }, [coin]);

  useEffect(() => {
    if (code.join('').match(/[0-9]{6}/)) {
      confirmAction();
    }
  }, [code]);

  const fee = coin?.networks.find((e) => e.network.name.toLowerCase() === network.toLowerCase())?.withdraw.fee || 0;

  return step === 0 ? (
    <>
      <div className='step stepDeposit'>
        <i>۱</i>
        <h3>انتخاب ارز</h3>
      </div>
      <SelectBox
        data={global.coins}
        search={true}
        value={
          coin ? (
            <>
              <img crossOrigin='anonymous' src={coin.icon} />
              <h3>
                {coin.name} ({coin.symbol.toUpperCase()})
              </h3>
            </>
          ) : (
            <>ارز خود را انتخاب کنید</>
          )
        }
        template='coin'
        setSelected={setCoin}
      />
      <div className='step stepDeposit'>
        <i>۲</i>
        <h3>انتخاب شبکه</h3>
      </div>
      <SelectBox
        data={coin?.networks}
        search={false}
        value={network ? <h3>{network}</h3> : <>نوع شبکه را انتخاب کنید</>}
        template='network'
        setSelected={setNetwork}
      />
      <div className='step stepDeposit'>
        <i>۳</i>
        <h3>آدرس مقصد</h3>
        <section className='addCard addCardAddress' onClick={() => dispatch(fullDrawer(<Address setData={setData} />))}>
          انتخاب از لیست
        </section>
      </div>
      <div className='mainInput'>
        <div>
          <span className='address'></span>
          <input type='text' value={address} onChange={(e) => setAddress(e.target.value)} />
        </div>
      </div>
      <div className='step stepDeposit'>
        <i>۴</i>
        <h3>مقدار برداشت</h3>
        {coin && (
          <section className='addCard addCardNo' onClick={() => setAmount(user.balance.asset[coin.symbol]?.free || 0)}>
            موجودی:{' '}
            <b className='inlinePrice'>
              {autoFormatter(user.balance.asset[coin.symbol]?.free || 0)} {coin.symbol.toUpperCase()}
            </b>
          </section>
        )}
      </div>
      <div className='mainInput'>
        <div>
          <NumericFormat
            inputMode='numeric'
            className='ltr'
            value={amount > 0 ? amount : ''}
            allowNegative={false}
            thousandSeparator={true}
            onValueChange={(e) => setAmount(Number(e.value))}
            placeholder='0.0'
          />
        </div>
      </div>
      {coin && network && (
        <div className='listCashCode listCashTop'>
          <section>
            <div>
              <span>کارمزد برداشت</span>
              <span className='inlinePrice'>
                {autoFormatter(fee)}
                {coin?.symbol.toUpperCase()}
              </span>
            </div>
            <div>
              <span>حداقل مقدار برداشت</span>
              <span className='inlinePrice'>
                {autoFormatter(coin.networks.find((e) => e.network.name.toLowerCase() === network.toLowerCase())?.withdraw.min || 0)}
                {coin?.symbol.toUpperCase()}
              </span>
            </div>
          </section>
        </div>
      )}
      <div className='spacer'></div>
      <div className='largeButton largeButtonGreen' onClick={() => saveAction()}>
        درخواست برداشت
      </div>
    </>
  ) : (
    <>
      <div className='voucherData voucherDataColor'>
        <div className='listCashCode listCashCodeCustom'>
          <section>
            <div>
              <span>ارز</span>
              <span className='currencyDetail'>
                {coin?.name} ({coin?.symbol.toUpperCase()})
                <img crossOrigin='anonymous' src={global.coins[coin?.symbol!].icon} />
              </span>
            </div>
          </section>
          <section>
            <div>
              <span>مبلغ</span>
              <span className='ltr'>{amount}</span>
            </div>
          </section>
          <section>
            <div>
              <span>کارمزد</span>
              <span className='ltr'>{fee}</span>
            </div>
          </section>
          <section>
            <div>
              <span>نهایی</span>
              <span className='ltr'>{amount - fee}</span>
            </div>
          </section>
          <section>
            <div>
              <span>دریافت کننده</span>
              <i></i>
              <span className='ltr'>{dotted(address)}</span>
            </div>
          </section>
          <section>
            <div>
              <span>تاریخ</span>
              <span className='ltr'>
                {moment()
                  .locale('fa')
                  .format('YYYY-MM-DD , HH:mm:ss')}
              </span>
            </div>
          </section>
        </div>
      </div>
      <div className='rules'>
        {type === 'sms' ? (
          <>
            کد تایید برای شماره <i>{user.profile.mobile}</i> پیامک شد
          </>
        ) : (
          <>
            کد تایید <i>Google Authenticator</i> را وارد نمایید
          </>
        )}
      </div>
      <PatchCode code={code} setCode={setCode} />
      <div className='spacer'></div>
      <div className='largeButton largeButtonGreen' onClick={() => confirmAction()}>
        تایید درخواست برداشت
      </div>
    </>
  );
};

export default Crypto;
