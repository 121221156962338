const banks = [
  {
    number: 627381,
    name: 'ansar',
    title: 'انصار',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/ansar-05.svg',
    color: '#26eeb900',
  },
  {
    number: 636214,
    name: 'ayandeh',
    title: 'آینده',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/ayande-03.svg',
    color: '#3388391d',
  },
  {
    number: 502938,
    name: 'dey',
    title: 'دی',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/day-12.svg',
    color: '#0dffffff',
  },
  {
    number: 627412,
    name: 'eghtesad_novin',
    title: 'اقتصادنوین',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/eghtesad-04.svg',
    color: '#1a662891',
  },
  {
    number: 628157,
    name: 'etebari_tosee',
    title: 'موسسه اعتباری توسعه',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/tose-11.svg',
    color: '#33000000',
  },
  {
    number: 505416,
    name: 'gardeshgari',
    title: 'گردشگری',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/gardeshgari-20.svg',
    color: '#29344143',
  },
  {
    number: 639599,
    name: 'ghavvamin',
    title: 'قوامین',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/ghavvamin-31.svg',
    color: '#1afff400',
  },
  {
    number: 627488,
    name: 'kar_afarin',
    title: 'کارآفرین',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/karafarin-06.svg',
    color: '#333a881d',
  },
  {
    number: 502910,
    name: 'kar_afarin',
    title: 'کارآفرین',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/karafarin-06.svg',
    color: '#333a881d',
  },
  {
    number: 603770,
    name: 'keshavarzi',
    title: 'کشاورزی',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/keshavarzi-07.svg',
    color: '#29aa9a22',
  },
  {
    number: 639217,
    name: 'keshavarzi',
    title: 'کشاورزی',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/keshavarzi-07.svg',
    color: '#29aa9a22',
  },
  {
    number: 628023,
    name: 'maskan',
    title: 'مسکن',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/maskan-26.svg',
    color: '#1a000000',
  },
  {
    number: 639370,
    name: 'mehr_e_eghtesad',
    title: 'مهر اقتصاد',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/mehreghtesad-14.svg',
    color: '#1a656565',
  },
  {
    number: 606373,
    name: 'mehr_e_iranian',
    title: 'قرض الحسنه مهر ایرانیان',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/mehriran-15.svg',
    color: '#1a209b1c',
  },
  {
    number: 603799,
    name: 'meli',
    title: 'ملی ایران',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/melli-22.svg',
    color: '#1a00e0d7',
  },
  {
    number: 610433,
    name: 'mellat',
    title: 'ملت',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/mellat-02.svg',
    color: '#1aba0b22',
  },
  {
    number: 991975,
    name: 'mellat',
    title: 'ملت',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/mellat-02.svg',
    color: '#1aba0b22',
  },
  {
    number: 111111,
    name: 'ok',
    title: 'همه کارتخوان‌ها',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/shetab.svg',
    color: '#1a00e0d7',
  },
  {
    number: 622106,
    name: 'parsian',
    title: 'پارسیان',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/pasargad-21.svg',
    color: '#29980000',
  },
  {
    number: 502229,
    name: 'pasargad',
    title: 'پاسارگاد',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/pasargad-10.svg',
    color: '#4d000000',
  },
  {
    number: 639347,
    name: 'pasargad',
    title: 'پاسارگاد',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/pasargad-10.svg',
    color: '#4d000000',
  },
  {
    number: 627760,
    name: 'post_bank',
    title: 'پست ایران',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/post-09.svg',
    color: '#1a14763e',
  },
  {
    number: 589463,
    name: 'refah',
    title: 'رفاه',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/refahkargaran-28.svg',
    color: '#0d000000',
  },
  {
    number: 627961,
    name: 'saanat_va_maadan',
    title: 'صنعت و معدن',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/sanatmadan-16.svg',
    color: '#339b814c',
  },
  {
    number: 603769,
    name: 'saderat',
    title: 'صادرات',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/saderat-29.svg',
    color: '#1a2d2a68',
  },
  {
    number: 621986,
    name: 'saman',
    title: 'سامان',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/saman-01.svg',
    color: '#1a7dcdf1',
  },
  {
    number: 639607,
    name: 'sarmayeh',
    title: 'سرمایه',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/sarmaye-17.svg',
    color: '#29bd9ff5',
  },
  {
    number: 589210,
    name: 'sepah',
    title: 'سپه',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/sepah-24.svg',
    color: '#33ffc400',
  },
  {
    number: 504706,
    name: 'shahr',
    title: 'شهر',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/shahr-25.svg',
    color: '#29ffafb2',
  },
  {
    number: 502806,
    name: 'shahr',
    title: 'شهر',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/shahr-25.svg',
    color: '#29ffafb2',
  },
  {
    number: 639346,
    name: 'sina',
    title: 'سینا',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/sina-23.svg',
    color: '#1a277eb5',
  },
  {
    number: 627353,
    name: 'tejarat',
    title: 'تجارت',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/tejarat-13.svg',
    color: '#290fadc7',
  },
  {
    number: 585983,
    name: 'tejarat',
    title: 'تجارت',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/tejarat-13.svg',
    color: '#290fadc7',
  },
  {
    number: 636949,
    name: 'tejarat',
    title: 'حکمت',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/hekmat-30.svg',
    color: '#29563288',
  },
  {
    number: 627648,
    name: 'tosee_saderat',
    title: 'توسعه صادرات',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/tosesaderat-18.svg',
    color: '#1f6d6900',
  },
  {
    number: 502908,
    name: 'tosee_taavon',
    title: 'توسعه تعاون',
    logo: 'https://basemax.github.io/DetectIranianBankWeb/images/tosetaavon-19.svg',
    color: '#290076ff',
  },
];

const detect = (number: string) => {
  if (!number || number.length < 6) {
    return null;
  }
  return banks.find((e) => e.number === Number(number.substring(0, 6))) || null;
};

const logo = (name: string) => {
  return banks.find((e) => e.name === name)?.logo || null;
};

export default {
  detect,
  logo,
};
