const View = ({ data }: any) => {
  return (
    <div className='popup popupTut'>
      <h1>{data.title}</h1>
      <article dangerouslySetInnerHTML={{ __html: data.text }}></article>
    </div>
  );
};

export default View;
