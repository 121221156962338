import { ICode } from 'interfaces/helper';
import { memo, useEffect } from 'react';

const PatchCode = ({ code, setCode }: ICode) => {
  const selectText = (e: any) => {
    e.target.select();
  };

  const setCodeData = (e: any) => {
    const form = e.target.form;
    const index = [...form].indexOf(e.target);

    const value = e.target.value.split('');
    if (index === 0 && value.length === 6) {
      setCode(value);
    } else {
      const value = e.target.value.slice(0, 1);

      setCode((prevCode) => {
        const newValue = [...prevCode];
        newValue[index] = value;
        return newValue;
      });

      if (index < 5 && value !== '' && e.target.name !== 5) {
        form.elements[index + 1].focus();
        form.elements[index + 1].select();
      }
    }
  };

  const list = () => {
    const rows: React.ReactElement[] = [];

    for (let i = 0; i <= 5; i++) {
      rows.push(
        <input
          type='number'
          inputMode='numeric'
          key={i}
          value={code[i] || ''}
          name={i.toString()}
          onChange={(e) => setCodeData(e)}
          onClick={(e) => selectText(e)}
        />,
      );
    }

    return rows;
  };

  useEffect(() => {
    if (code.length === 0) {
      // console.log('A', code);
    }
  }, [code]);

  return <form className='verifyCode'>{list()}</form>;
};

export default memo(PatchCode);
