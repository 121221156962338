import Top from 'components/Layouts/Top';
import config from 'config';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import View from './View';
import { centerDialog } from 'services/reducer/dialog';

const Tutorial = () => {
  const [pages, setPages] = useState([]);
  const [category, setCategory] = useState('');
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  let filter = pages;
  if (category) {
    filter = filter.filter((e: any) => e.category === category);
  }
  if (search) {
    filter = filter.filter((e: any) => e.title.includes(search) || e.text.includes(search) || e.slug.includes(search));
  }

  const pagesRender = () => {
    const rows: JSX.Element[] = [];

    filter.forEach((e: any) => {
      rows.push(<span onClick={() => dispatch(centerDialog(<View data={e} />))}>{e.title}</span>);
    });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/page`, true).get();
    if (result.code === 200) {
      setPages(result.data);
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title='آموزش' />
        <div className='container inTut'>
          <h5>چجوری میتوانیم کمکتان کنیم؟</h5>
          <div className='searchTut'>
            <i className='searchIcon'></i>
            <input type='text' placeholder='جستجو کنید' value={search} onChange={(e) => setSearch(e.target.value)} />
            <span>جستجو کنید</span>
          </div>
          {category || search ? (
            <>
              <div
                className='backToPage'
                onClick={() => {
                  setCategory('');
                  setSearch('');
                }}
              >
                بازگشت به دسته بندی
              </div>
              <div className='pageList'>{pagesRender()}</div>
            </>
          ) : (
            <div className='tutList'>
              <span onClick={() => setCategory('products')}>محصولات اکسنوین</span>
              <span onClick={() => setCategory('fiat')}>واریز و برداشت تومان</span>
              <span onClick={() => setCategory('depositWithdraw')}>واریز و برداشت رمزارز</span>
              <span onClick={() => setCategory('registerKyc')}>ثبت نام و احراز هویت</span>
              <span onClick={() => setCategory('buySell')}>خرید و فروش</span>
              <span onClick={() => setCategory('wallet')}>کیف پول</span>
              <span onClick={() => setCategory('other')}>سایر</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
