import Tabs from 'components/Helper/Tabs';
import Bottom from 'components/Layouts/Bottom';
import Header from 'components/Layouts/Header';
import PreLogin from 'components/Layouts/PreLogin';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import { IDispatch } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { centerDialog } from 'services/reducer/dialog';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getFavorite } from 'services/reducer/user';
import { priceColor } from 'utils/color';
import gather from 'utils/gather';
import { autoFormatter, formatter } from 'utils/numeral';
import { isUserLogin } from 'utils/user';

const tabTitles = {
  all: 'همه ارزها',
  fav: 'مورد علاقه',
};

const Price = () => {
  const dispatch = useDispatch<IDispatch>();
  const global = useGlobal();
  const user = useUser();
  const ticker = useTicker();
  const [text, setText] = useState('');
  const [tab, setTab] = useState(tabTitles.all);

  const changeFavorite = async (id: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/favorite`, true)[user.favorite.includes(id) ? 'delete' : 'post']({
      coin: id,
    });

    if (result.code === 200) {
      await dispatch(getFavorite());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  const priceRender = () => {
    const rows: JSX.Element[] = [];

    let z = 0;
    Object.values(global.coins)
      .filter((e) => e.isActive)
      .filter(
        (e) =>
          e.name?.toLowerCase().includes(text.toLowerCase()) ||
          e.nameFa?.toLowerCase().includes(text.toLowerCase()) ||
          e.symbol?.toLowerCase().includes(text.toLowerCase()),
      )
      .filter((e) => (tab === tabTitles.all ? e.symbol : user.favorite.includes(e._id)))
      // .sort((a, b) => b.value.usdt - a.value.usdt)
      // .filter((e) => (small ? e.value.usdt >= 1 : e.value.usdt >= 0))
      .forEach((e, i) => {
        const priceTmn = ticker.price(e.symbol + 'tmn');
        const priceUsdt = ticker.price(e.symbol + 'usdt');
        const changeTmn = ticker.change(e.symbol + 'tmn');
        const changeUsdt = ticker.change(e.symbol + 'usdt');

        rows.push(
          <div key={`${i}`}>
            <section>
              <i>{i + 1}</i>
              <img crossOrigin='anonymous' src={e.icon} />
              <h2>{e.nameFa}</h2>
              <h3>{e.symbol.toUpperCase()}</h3>
              <b
                className={`fav fav_${user.favorite.includes(e._id)}`}
                onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : changeFavorite(e._id))}
              ></b>
            </section>
            <section>
              {priceUsdt > 0 && (
                <div>
                  <i className={`color${priceColor(changeUsdt)}`}>{autoFormatter(changeUsdt)}%</i>
                  <p>{formatter(priceUsdt, e.pair.usdt.precision)} USDT</p>
                </div>
              )}
              {priceTmn > 0 && (
                <div>
                  <i className={`color${priceColor(changeTmn)}`}>{autoFormatter(changeTmn)}%</i>
                  <p>{formatter(priceTmn, e.pair.tmn.precision)} TMN</p>
                </div>
              )}
            </section>
          </div>,
        );
      });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return rows;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header title={'نرخ روز'} />
      <div className='priceList'>
        <div className='container'>
          <div className='search searchPrice'>
            <input type='text' className='ltr textRight' onChange={(e) => setText(e.target.value)} placeholder='جستجوی ارز' />
          </div>
          <div className='tabsPrice'>
            <Tabs titles={tabTitles} selected={tab} setSelected={setTab} />
          </div>
          <div className='priceDay'>{priceRender()}</div>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default Price;
