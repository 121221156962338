import Top from 'components/Layouts/Top';
import useUser from 'hooks/useUser';
import { useState } from 'react';
import Code from './Code';
import Form from './Form';
import Verify from './Verify';

const Identity = () => {
  const stepsTitles = ['ثبت درخواست', 'تایید ایمیل قدیم', 'تایید ایمیل جدید'];
  const user = useUser();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');

  if (!user.profile.email) {
    stepsTitles.splice(1, 1);
  }

  const stepsRender = () => {
    const rows: React.ReactElement[] = [];

    stepsTitles.forEach((e, i) => {
      rows.push(
        <div key={i} className={i + 1 <= step ? 'active' : ''}>
          <span></span>
          {e}
        </div>,
        <b key={i + 10}></b>,
      );
    });

    return rows;
  };

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'ثبت ایمیل'} />
        <div className='allSteps'>{stepsRender()}</div>
        <div className='container inData'>
          {step === 1 ? (
            <Form setStep={setStep} setEmail={setEmail} email={email} />
          ) : step === 2 ? (
            <Code setStep={setStep} />
          ) : (
            <Verify setStep={setStep} email={email} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Identity;
