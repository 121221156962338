import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import { ITicker } from 'interfaces/global';
import gather from 'utils/gather';

const initialState = {
  tickers: {} as { [k: string]: ITicker },
};

const getTickers = createAsyncThunk('price/fetchPrice', async () => {
  return await gather(config.data.api + '/v1/ticker').get();
});

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updateTicker: (state, action) => {
      action.payload.forEach((e: ITicker) => {
        state.tickers[e.coin + e.pair] = e;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTickers.fulfilled, (state, action) => {
      if (action.payload.code === 200) {
        action.payload.data.forEach((value: ITicker) => {
          state.tickers[value.coin + value.pair] = value;
        });
      }
    });
  },
});

export { getTickers };
export const { updateTicker } = slice.actions;
export default slice.reducer;
