import Tabs from 'components/Helper/Tabs';
import Top from 'components/Layouts/Top';
import { useEffect, useState } from 'react';
import Code from './Code';
import Crypto from './Crypto';
import Fiat from './Fiat';

const tabTitles = {
  toman: 'تومان',
  more: '۲۵+ میلیون تومان',
  crypto: 'ارز دیجیتال',
};

const Deposit = ({ symbol }: { symbol?: string }) => {
  const [tab, setTab] = useState(tabTitles.toman);

  useEffect(() => {
    if (symbol) {
      if (symbol !== 'tmn') {
        setTab(tabTitles.crypto);
      }
    }
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'واریز'} />
        <div className='container inData'>
          <div className='tabs tabsTop'>
            <Tabs titles={tabTitles} selected={tab} setSelected={setTab} />
          </div>
          {tab === tabTitles.toman ? <Fiat /> : tab === tabTitles.more ? <Code /> : <Crypto symbol={symbol} />}
        </div>
        {tab === tabTitles.more && (
          <div className='container codeAlert'>
            <p>کاربر گرامی، به دستور پلیس فتا محترم ؛ بعد از استفاده از واریز شناسه‌دار، امکان برداشت ارز دیجیتال تا ۳۶ ساعت را ندارید.</p>
          </div>
        )}
        <div className='container inData'>
          <h4 className='notesTitle'>نکات مهم</h4>
          <div className='noteList'>
            {tab === tabTitles.toman ? (
              <>
                <span>
                  بعداز پرداخت از طریق درگاه الکترونیک شاپرک ، در صورت عدم شارژ کیف پول تومان ، لطفا صفحه خود را بعداز 2 دقیقه refresh کنید و مجدد بررسی کنید.
                </span>
                <span>
                  برای واریز ریال یکی از کارت های تایید شده را انتخاب کنید و اگر کارت تایید شده ندارید در قسمت کارت های بانکی در بخش حساب بانکی اضافه کنید.
                </span>
                <span>دقت کنید ، کارت بانکی که قصد ثبت در پروفایلتان دارید باید با کد ملی شما مطابقت داشته باشد.</span>
                <span>
                  با توجه به ابلاغیه بانک مرکزی در مورد نظام کارمزد شاپرکی و همچنین کارمزد سرویس های پرداخت یاری، واریز ریالی از درگاه پرداخت به حساب کاربری در
                  اکسنوین مشمول کارمزد خواهد بود.
                </span>
              </>
            ) : tab === tabTitles.more ? (
              <>
                <span>برای استفاده از سرویس واریز شناسه دار سطح کاربری 2 به بالا نیاز است.</span>
                <span>
                  با انتخاب دکمه آبی رنگ " درخواست صدور شناسه واریز " سیستم به صورت خودکار ، شناسه واریز / شناسه پرداخت شما را ایجاد کرده و میتوانید بلافاصله از
                  این سرویس استفاده کنید.
                </span>
                <span>در ادامه شناسه واریز یا همان شناسه پرداخت برای شما ایجاد می شود و لیست حساب ها قابل مشاهده می باشد.</span>
                <span>
                  در آخر هنگام واریز وجه از روش های پایا، ساتنا و حساب به حساب حتما در بخش شناسه‌ی واریز، شناسه‌ی دریافتی خود را به دقت وارد کنید ، در غیر
                  اینصورت تراکنش شما عودت خواهد شد.
                </span>
                <span>توجه داشته باشید که پس از وصول مبلغ به حساب های اکسنوین ، کیف پول تومانی شما شارژ خواهد شد</span>
              </>
            ) : (
              <>
                <span>در این صفحه آدرس واریزی کیف پول (شامل آدرس و یا در صورت لزوم تگ/ ممو و شبکه واریزی دلخواه خود را انتخاب و کپی کنید.</span>
                <span>آدرس کیف پول و در صورت لزوم آدرس تگ/ممنو را از صفحه کپی کنید و هنگام انتقال ارز دیجیتال در کیف پول ( مبدا ) خود ، وارد کنید.</span>
                <span>سپس منتظر بمانید ، بعداز تایید (confirm) شبکه ، به صورت خودکار مقدار رمزارز انتقالی به کیف پول شما واریز خواهد شد.</span>
                <span>
                  در صورت هرگونه مشکل یا مغایرت ، Hash تراکنش یا همان Transaction ID به همراه اسکرین شات انتقال رمزارز ، از طریق تیکت به پشتیبانی ارسال کنید.
                </span>
              </>
            )}
          </div>
          <div className='spacer'></div>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
