import { Backdrop, CircularProgress } from '@mui/material';
import InstallPWA from 'components/Layouts/InstallPWA';
import Digital from 'components/Pages/Digital';
import Home from 'components/Pages/Home';
import Market from 'components/Pages/Market';
import Price from 'components/Pages/Price';
import Wallet from 'components/Pages/Wallet';
import useLoading from 'hooks/useLoading';
import { IDispatch } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getCoins, getSetting } from 'services/reducer/global';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getTickers } from 'services/reducer/ticker';
import { connect, socket } from 'services/socket';
import { checkAuth, getUserData, isUserLogin } from 'utils/user';
import AlertList from './AlertList';
import DialogList from './DialogList';
import DrawerList from './DrawerList';

const App = () => {
  const dispatch = useDispatch<IDispatch>();
  const { isLoadingShow } = useLoading();
  const [isReady, setIsReady] = useState(false);

  const init = async () => {
    dispatch(showLoading());

    await checkAuth();

    await Promise.all([dispatch(getCoins()), dispatch(getTickers()), dispatch(getSetting())]);

    if (isUserLogin()) {
      await getUserData(dispatch);
    }

    connect(dispatch);

    socket.on('connect', () => {
      setIsReady(true);
      dispatch(hideLoading());
    });
  };

  useEffect(() => {
    // const firebaseConfig = {
    //   apiKey: 'AIzaSyCE5NQzm787iRBro4ak72_JA8sBySDDfQA',
    //   authDomain: 'exnovinco-b49cd.firebaseapp.com',
    //   projectId: 'exnovinco-b49cd',
    //   storageBucket: 'exnovinco-b49cd.appspot.com',
    //   messagingSenderId: '444863108108',
    //   appId: '1:444863108108:web:d48f3e71f436a09ec80a29',
    //   measurementId: 'G-LL5DD7R4WX',
    // };

    // const app = initializeApp(firebaseConfig);
    // const messaging = getMessaging(app);

    // getToken(messaging)
    //   .then((currentToken) => {
    //     if (currentToken) {
    //       // console.log('currentToken', currentToken);
    //       onMessage(messaging, (a) => {
    //         console.log('messaging', a);
    //       });
    //     } else {
    //       console.log('No registration token available. Request permission to generate one.');
    //     }
    //   })
    //   .catch((err) => {
    //     console.log('An error occurred while retrieving token. ', err);
    //   });

    init();
  }, []);

  return (
    <BrowserRouter basename='/'>
      <div className='app'>
        <Backdrop sx={{ backgroundColor: '#ffffffe8', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }} open={isLoadingShow}>
          <CircularProgress />
        </Backdrop>
        <AlertList />
        <DrawerList />
        <DialogList />
        <InstallPWA />
        {isReady && (
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/wallet' element={<Wallet />} />
            <Route path='/market' element={<Market />} />
            <Route path='/digital' element={<Digital />} />
            <Route path='/price' element={<Price />} />
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
};

export default App;
