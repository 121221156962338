import Tabs from 'components/Helper/Tabs';
import Bottom from 'components/Layouts/Bottom';
import Header from 'components/Layouts/Header';
import Orders from 'components/Layouts/Orders';
import Pairs from 'components/Layouts/Pairs';
import PreLogin from 'components/Layouts/PreLogin';
import VerifyList from 'components/Layouts/VerifyList';
import Deposit from 'components/Modules/Deposit';
import config from 'config';
import { EPair } from 'enums/global';
import useBalance from 'hooks/useBalance';
import useGlobal from 'hooks/useGlobal';
import useTicker from 'hooks/useTicker';
import useUser from 'hooks/useUser';
import { IBuySell } from 'interfaces/global';
import { invert } from 'lodash';
import { useEffect, useState } from 'react';
import Slider from 'react-input-slider';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { centerDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import { autoFormatter, toFixed } from 'utils/numeral';
import { isUserLogin } from 'utils/user';
import Chart from './Chart';
import OrderBook from './OrderBook';

const tradeTabTitles = {
  limit: 'قیمت محدود',
  market: 'قیمت فوری',
  stop: 'حد ضرر/سود',
};

const sideTabTitles = {
  buy: 'خرید',
  sell: 'فروش',
};

const marketTabTitles = {
  tmn: 'تومان',
  usdt: 'تتر',
};

const findSum = (data: IBuySell[], amount: number, precision: number) => {
  let sum = 0;
  let price = 0;
  let count = 0;

  for (const e of data) {
    if (amount <= sum) {
      break;
    }

    count++;
    sum += e.amount;
    price += e.price;
  }

  return toFixed(price / count, precision);
};

const Home = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const global = useGlobal();
  const ticker = useTicker();
  const balance = useBalance();
  const user = useUser();
  const [coin, setCoin] = useState(state?.default || Object.values(global.coins).find((e) => e.symbol === 'usdt')!);
  const [pair, setPair] = useState(EPair.tmn);
  const [amount, setAmount] = useState(0);
  const [stop, setStop] = useState(0);
  const [customPrice, setCustomPrice] = useState(0);
  const [tradeTab, setTradeTab] = useState(tradeTabTitles.limit);
  const [sideTab, setSideTab] = useState(sideTabTitles.buy);
  const [reload, setReload] = useState(0);
  const [range, setRange] = useState({ x: 0 });
  const [orderList, setOrderList] = useState<{ buy: IBuySell[]; sell: IBuySell[] }>({ buy: [], sell: [] });

  const side = sideTab === sideTabTitles.buy ? 'buy' : 'sell';
  const coinPrice = ticker.price(coin.symbol + pair);
  const sum = amount > 0 ? findSum(orderList[sideTab === sideTabTitles.buy ? 'sell' : 'buy'], amount, coin.pair[pair].precision) : coinPrice;
  const currentPrice = tradeTab === tradeTabTitles.limit ? customPrice : sum;
  const userAsset = balance[sideTab === sideTabTitles.buy ? pair : coin.symbol];
  const trade = invert(tradeTabTitles)[tradeTab] as any;
  const fee = user?.profile?.plane?.level?.fee?.[side] || 0;

  console.log('coin', coin.decimal);

  const resetInput = () => {
    setAmount(0);
    setCustomPrice(0);
    setRange({ x: 0 });
  };

  const buyOrSellAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/order`, true).post({
      coin: coin._id,
      price: currentPrice,
      pair: pair,
      stop: stop,
      side: sideTab === sideTabTitles.buy ? 'buy' : 'sell',
      trade: tradeTab === tradeTabTitles.stop ? 'stop' : tradeTab === tradeTabTitles.limit ? 'limit' : 'market',
      amount: amount,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert('سفارش شما با موفقیت ثبت شد'));
      setReload(reload + 1);
      resetInput();
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  useEffect(() => {
    if (sideTab === sideTabTitles.sell) {
      setAmount(toFixed((userAsset.balance * range.x) / 100, coin.decimal));
    } else {
      if (!currentPrice) {
        setAmount(0);
      } else {
        setAmount(toFixed(((userAsset.balance / currentPrice) * range.x) / 100, coin.decimal));
      }
    }
  }, [range]);

  useEffect(() => {
    resetInput();
  }, [sideTab, tradeTab, coin]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header title={'بازار حرفه ای'} />
      <VerifyList />
      <div className='whiteBg buyExpress'>
        <div className='container'>
          <div className='headMarket'>
            <div className='tabs'>
              <Tabs titles={tradeTabTitles} selected={tradeTab} setSelected={setTradeTab} />
            </div>
            <div className='chart' onClick={() => dispatch(fullDrawer(<Chart symbol={coin.symbol.toUpperCase()} />))}></div>
            <div
              className='marketTitle'
              onClick={() => dispatch(fullDrawer(<Pairs setCoin={setCoin} setPair={setPair} pair={['tmn', 'usdt']} trade={trade} />))}
            >
              <h3>
                {coin.symbol.toUpperCase()}/{pair.toUpperCase()}
                <i>
                  {coin.nameFa}/{marketTabTitles[pair]}
                </i>
              </h3>
              <img crossOrigin='anonymous' src={coin.icon} />
              <img crossOrigin='anonymous' src={`./images/${pair}.png`} />
            </div>
          </div>
          <div
            className='yourBalance yourBalanceMarket'
            onClick={() => dispatch(fullDrawer(<Deposit symbol={sideTab === sideTabTitles.buy ? pair : coin.symbol} />))}
          >
            <div>
              موجودی در دسترس:{' '}
              <i>
                {autoFormatter(isUserLogin() ? userAsset.balance : 0)} {sideTab === sideTabTitles.buy ? pair.toUpperCase() : coin.symbol.toUpperCase()}
              </i>
            </div>
          </div>
        </div>
        <div className='container inMarket'>
          <div className='rightMarket'>
            <div className='sellBuy'>
              <Tabs titles={sideTabTitles} selected={sideTab} setSelected={setSideTab} />
            </div>
            <div className='formFast'>
              {tradeTabTitles.stop === tradeTab && (
                <div className='payed'>
                  <NumericFormat
                    inputMode='numeric'
                    className='ltr textRight'
                    value={stop > 0 ? stop : ''}
                    allowNegative={false}
                    thousandSeparator={true}
                    onValueChange={(e) => setStop(Number(e.value))}
                    placeholder='حد ضرر'
                  />
                  <span>{pair.toUpperCase()}</span>
                </div>
              )}
              <div className='payed'>
                <NumericFormat
                  inputMode='numeric'
                  className='ltr textRight'
                  value={amount > 0 ? amount : ''}
                  allowNegative={false}
                  thousandSeparator={true}
                  onValueChange={(e) => setAmount(Number(e.value))}
                  placeholder='مقدار'
                />
                <span>{coin.symbol.toUpperCase()}</span>
              </div>
              <div className={`payed ${tradeTabTitles.market === tradeTab ? 'payed_true' : 'payed_false'}`}>
                <NumericFormat
                  inputMode='numeric'
                  disabled={tradeTabTitles.market === tradeTab ? true : false}
                  className='ltr textRight'
                  value={tradeTabTitles.market === tradeTab ? (currentPrice > 0 ? currentPrice : '') : customPrice > 0 ? customPrice : ''}
                  allowNegative={false}
                  thousandSeparator={true}
                  onValueChange={(e) => setCustomPrice(Number(e.value))}
                  placeholder='قیمت'
                />
                <span>{pair.toUpperCase()}</span>
              </div>
              <div className='bestPrice' onClick={() => setCustomPrice(orderList[sideTab !== sideTabTitles.buy ? 'buy' : 'sell']?.[0]?.price)}>
                <span>بهترین پیشنهاد {sideTab === sideTabTitles.buy ? 'فروش' : 'خرید'}</span>
                <p>
                  {autoFormatter(orderList[sideTab !== sideTabTitles.buy ? 'buy' : 'sell']?.[0]?.price || 0)} {pair.toUpperCase()}
                </p>
              </div>
              <div className='runRange'>
                <Slider
                  x={range.x}
                  xreverse={true}
                  styles={{
                    track: {
                      backgroundColor: '#DFDFDF',
                    },
                    active: {
                      backgroundColor: '#2424DA',
                    },
                    thumb: {
                      backgroundColor: '#2424DA',
                      boxShadow: 'none',
                    },
                  }}
                  onChange={setRange}
                />
                <span style={{ width: range.x + '%' }}></span>
                <section>
                  <i>0%</i>
                  <i>25%</i>
                  <i>50%</i>
                  <i>75%</i>
                  <i>100%</i>
                </section>
              </div>
              <div className='yourBalance'>
                <div>
                  جمع کل:{' '}
                  <i>
                    {autoFormatter((sideTabTitles.buy === sideTab ? amount * currentPrice : amount) || 0)}{' '}
                    {sideTabTitles.buy === sideTab ? pair.toUpperCase() : coin.symbol.toUpperCase()}
                  </i>
                </div>
              </div>
              <div className='receive'>
                <div>
                  کارمزد
                  <i>{fee} درصد</i>
                </div>
                <div>
                  دریافتی شما
                  <i>
                    {autoFormatter(((sideTabTitles.sell === sideTab ? amount * currentPrice : amount) || 0) * (1 - fee / 100))}{' '}
                    {sideTab === sideTabTitles.sell ? pair.toUpperCase() : coin.symbol.toUpperCase()}
                  </i>
                </div>
              </div>
              <div
                className={`agreePay ${sideTab === sideTabTitles.sell ? 'bgRed' : 'bgGreen'}`}
                onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : buyOrSellAction())}
              >
                ثبت {sideTab} {coin.symbol.toUpperCase()}
              </div>
            </div>
          </div>
          <OrderBook
            orderList={orderList}
            setOrderList={setOrderList}
            coin={coin}
            pair={pair}
            coinPrice={coinPrice}
            precision={coin.pair[pair].precision}
            setCustomPrice={setCustomPrice}
          />
        </div>
      </div>
      <Orders reload={reload} />
      <Bottom />
    </>
  );
};

export default Home;
