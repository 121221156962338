import config from 'config';
import { IDispatch } from 'interfaces/global';
import Cookies from 'js-cookie';
import { getAccountBalance, getCards, getFavorite, getProfile, getWalletBalance } from 'services/reducer/user';
import gather from './gather';

const isUserLogin = () => {
  if (Cookies.get('pwaIsLogin')) {
    return true;
  }
  return false;
};

const logoutUser = (redirect = false, fromServer = false) => {
  if (fromServer) {
    gather(`${config.data.api}/v1/account/user/logout`, true).post();
  }

  Cookies.remove('pwaAccessToken', { path: '/' });
  Cookies.remove('pwaRefreshToken', { path: '/' });

  if (redirect) {
    window.location.reload();
  }
};

const checkAuth = async () => {
  Cookies.remove('pwaIsLogin', { path: '/' });

  if (Cookies.get('pwaRefreshToken')) {
    const result = await gather(`${config.data.api}/v1/auth/refreshToken`).get({
      authorization: Cookies.get('pwaRefreshToken'),
    });

    if (result) {
      if (result.code === 200) {
        Cookies.set('pwaAccessToken', result.data.accessToken, { expires: 30 * 24 * 60 * 60, path: '/' });
        Cookies.set('pwaIsLogin', '1', { expires: 30 * 24 * 60 * 60, path: '/' });
      } else {
        logoutUser();
      }
    } else {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await checkAuth();
    }
  }
};

const getUserData = async (dispatch: IDispatch) => {
  await Promise.all([dispatch(getProfile()), dispatch(getFavorite()), dispatch(getAccountBalance()), dispatch(getWalletBalance()), dispatch(getCards())]);
};

export { checkAuth, getUserData, isUserLogin, logoutUser };
