import config from 'config';
import useUser from 'hooks/useUser';
import { IForm } from 'interfaces/email';
import { IDispatch } from 'interfaces/global';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Form = ({ setStep, email, setEmail }: IForm) => {
  const user = useUser();
  const dispatch = useDispatch<IDispatch>();

  const saveAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/email`, true).post({
      email: email,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      setStep(!user.profile.email ? 3 : 2);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  return (
    <>
      <div className='emailLogo'></div>
      <div className='rules rulesCenter'>ایمیل جدید خود را در کادر زیر وارد نمایید</div>
      <div className='mainInput'>
        <div>
          <input type='text' className='ltr' onChange={(e) => setEmail(e.target.value)} />
        </div>
      </div>
      <div className='spacer'></div>
      <div className='largeButton' onClick={() => saveAction()}>
        تایید و ادامه
      </div>
    </>
  );
};

export default Form;
