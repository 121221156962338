import config from 'config';
import { IForm } from 'interfaces/register';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';
import Login from '../Login';

const Form = ({ mobile, setMobile, setStep }: IForm) => {
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [passwordEye, setPasswordEye] = useState(false);
  const [repasswordEye, setRepasswordEye] = useState(false);
  const [rules, setRules] = useState(false);
  const dispatch = useDispatch();

  const registerAction = async () => {
    if (password !== repassword) {
      dispatch(errorAlert('رمز عبور و تکرار آن مشابه نمی باشد'));
      return;
    }

    if (!rules) {
      dispatch(errorAlert('لطفا ابتدا تیک پذیرش قوانین را پر نمایید'));
      return;
    }

    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/auth/signup`).post({
      mobile: mobile.startsWith('0') ? mobile : '0' + mobile,
      password: password,
      repassword: repassword,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      setStep(2);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  return (
    <>
      <p className='inTitle'>عضویت در اکس نوین</p>
      <p className='inAlarm'>لطفا فقط با آدرس زیر وارد شوید</p>
      <div className='domain'>
        <i>https://</i>pwa.exnovin.net
      </div>
      <div className='mainInput'>
        <p>شماره همراه</p>
        <div>
          <span></span>
          <input type='phone' inputMode='tel' placeholder='+98' onChange={(e) => setMobile(e.target.value)} />
        </div>
      </div>
      <div className='mainInput'>
        <p>رمز عبور</p>
        <div>
          <span className='password'></span>
          <input type={!passwordEye ? 'password' : 'text'} autoComplete='one-time-code' onChange={(e) => setPassword(e.target.value)} />
          <i className={`eye ${passwordEye && 'active'}`} onClick={() => setPasswordEye(!passwordEye)}></i>
        </div>
      </div>
      <div className='mainInput'>
        <p>تکرار رمز عبور</p>
        <div>
          <span className='password'></span>
          <input type={!repasswordEye ? 'password' : 'text'} autoComplete='one-time-code' onChange={(e) => setRepassword(e.target.value)} />
          <i className={`eye ${repasswordEye && 'active'}`} onClick={() => setRepasswordEye(!repasswordEye)}></i>
        </div>
      </div>
      <div className='rules' onClick={() => setRules(!rules)}>
        <span className={rules ? 'active' : ''}></span>
        <i>قوانین و مقررات</i>سایت را مطالعه نمودم و تایید میکنم
      </div>
      <div className='largeButton' onClick={() => registerAction()}>
        تکمیل ثبت نام
      </div>
      <div
        className='haveAccount'
        onClick={() => {
          dispatch(hideDrawer());
          setTimeout(() => dispatch(fullDrawer(<Login />)), 300);
        }}
      >
        <i>حساب کاربری دارید؟</i>
        <p>وارد شوید</p>
      </div>
    </>
  );
};

export default Form;
