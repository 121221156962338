import { useDispatch } from 'react-redux';
import { hideDialog } from 'services/reducer/dialog';

const OptionForm = ({ value, def, option, setOption }: any): React.ReactElement => {
  const dispatch = useDispatch();

  let data = value;
  if (!(value instanceof Map)) {
    data = new Map();

    Object.keys(value).forEach((key: any) => {
      const e = value[key];
      data.set(key, e);
    });
  }

  const list = () => {
    const rows: React.ReactElement[] = [];

    for (let [k, v] of data) {
      rows.push(
        <span
          className={def === k ? 'active' : ''}
          key={k}
          onClick={() => {
            setOption(k);
            dispatch(hideDialog());
          }}
        >
          {v}
        </span>,
      );
    }

    return rows;
  };

  return <div className={`popup popupDown optionList ${option?.direct === 'ltr' && 'optionListLtr'}`}>{list()}</div>;
};

export default OptionForm;
