import { Dialog } from '@mui/material';
import useDialog from 'hooks/useDialog';
import { IDialog } from 'interfaces/reducer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideDialog, showDialog } from 'services/reducer/dialog';

const check: boolean[] = [];

const DialogList = () => {
  const dispatch = useDispatch();
  const { dialogs } = useDialog();

  const handleClose = async () => {
    dispatch(hideDialog());
  };

  const list = () => {
    const rows: React.ReactElement[] = [];

    dialogs.forEach((value: IDialog, i: number) => {
      setTimeout(() => {
        if (!check[i]) {
          dispatch(showDialog());
          check[i] = true;
        }
      }, 50);

      rows.push(
        <Dialog key={i} PaperProps={{ elevation: 0 }} open={value.open} onClose={() => handleClose()}>
          {value.content}
        </Dialog>,
      );
    });

    return rows;
  };

  useEffect(() => {
    if (dialogs[0]?.open) {
      document.body.classList.add('hasDialog');
    } else {
      document.body.classList.remove('hasDialog');
    }
  }, [dialogs]);

  return <div className='dialogParent'>{list()}</div>;
};

export default DialogList;
