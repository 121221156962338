import useUser from 'hooks/useUser';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { centerDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import { isUserLogin } from 'utils/user';
import Menu from './Menu';
import PreLogin from './PreLogin';

const Bottom = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useUser();

  return (
    <div className='menu'>
      <div className={location.pathname === '/price' ? 'active' : ''} onClick={() => navigate('/price')}>
        نرخ روز
      </div>
      <div className={location.pathname === '/market' ? 'active' : ''} onClick={() => navigate('/market')}>
        بازارها
      </div>
      <div className={location.pathname === '/' ? 'active' : ''} onClick={() => navigate('/')}>
        مبادله
      </div>
      {profile.isOld ? (
        <div className={location.pathname === '/digital' ? 'active' : ''} onClick={() => navigate('/digital')}>
          ارزها
        </div>
      ) : (
        <div
          className={location.pathname === '/digital' ? 'active' : ''}
          onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : dispatch(fullDrawer(<Menu />)))}
        >
          پروفایل
        </div>
      )}
      <div
        className={location.pathname === '/wallet' ? 'active' : ''}
        onClick={() => (!isUserLogin() ? dispatch(centerDialog(<PreLogin />)) : navigate('/wallet'))}
      >
        کیف پول
      </div>
    </div>
  );
};

export default Bottom;
