import moment from 'jalali-moment';
import { statusColor, typeColor } from 'utils/color';
import { payTranslate, sideTranslate, statusTranslate } from 'utils/translate';

const Payment = ({ data }: any) => {
  return (
    <div className='voucherData'>
      <div className='listCashCode listCashCodeCustom'>
        <section>
          <div>
            <span>نوع</span>
            <span className={`color${typeColor(data.side)}`}>{sideTranslate(data.side)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>ارز</span>
            <span className='currencyDetail'>
              Toman (TMN)
              <img crossOrigin='anonymous' src='./images/tmn.png' />
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>وضعیت</span>
            <span className={`boxStatus bg${statusColor(data.status)}`}>{statusTranslate(data.status)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>روش پرداخت</span>
            <span>{payTranslate(data.type)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>مبلغ</span>
            <span className='ltr'>{data.amount} TMN</span>
          </div>
        </section>
        <section>
          <div>
            <span>کارمزد</span>
            <span className='ltr'>{data.fee} TMN</span>
          </div>
        </section>
        <section>
          <div>
            <span>نهایی</span>
            <span className='ltr'>{data.final} TMN</span>
          </div>
        </section>
        <section>
          <div>
            <span>تاریخ</span>
            <span className='ltr'>{moment(data.createdAt).locale('fa').format('YYYY-MM-DD , HH:mm:ss')}</span>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Payment;
