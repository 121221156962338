import Top from 'components/Layouts/Top';

const Alert = ({ success }: { success: string }) => {
  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'بررسی پرداخت'} />
        <div className='container inData rulesCenter'>
          {success === 'true' ? (
            <div className='confirmData'>
              <span></span>
              <h2>پرداخت تایید شد</h2>
              <h3>تبریک! پرداخت شما تایید شد و هم اکنون به اعتبار شما افزوده شده است</h3>
            </div>
          ) : (
            <div className='confirmData cancelData'>
              <span></span>
              <h2>پرداخت تایید نشد</h2>
              <h3>متاسفانه پرداخت شما تایید نشد و در صورت کسر از حساب طی ۷۲ ساعت بازگشت داده خواهد شد</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;
