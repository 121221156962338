import Bottom from 'components/Layouts/Bottom';
import Header from 'components/Layouts/Header';
import Deposit from 'components/Modules/Deposit';
import History from 'components/Modules/History';
import Withdraw from 'components/Modules/Withdraw';
import useBalance from 'hooks/useBalance';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { centerDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import { autoFormatter } from 'utils/numeral';
import Alert from './Alert';
import Trade from './Trade';

const Wallet = () => {
  const dispatch = useDispatch();
  const { profile } = useUser();
  const balance = useBalance();

  const [small, setSmall] = useState(false);
  const [eye, setEye] = useState(profile.setting.hideAsset || false);
  const [text, setText] = useState('');
  const [search, setSearch] = useState(false);
  const [searchParams, _setSearchParams] = useSearchParams();

  const balanceRender = () => {
    const rows: JSX.Element[] = [];

    Object.values(balance)
      .filter((e) => e.name?.includes(text) || e.nameFa?.includes(text) || e.symbol?.includes(text))
      .sort((a, b) => {
        if (a.symbol === 'tmn') {
          return -1;
        }
        return b.value.usdt - a.value.usdt;
      })
      .filter((e) => (small ? e.value.usdt >= 1 : e.value.usdt >= 0))
      .forEach((e, i) => {
        rows.push(
          <div key={i}>
            <span className='w120 inlineFlex'>
              <img crossOrigin='anonymous' src={e.icon} />
              <i className='viaFa'>
                {e.symbol.toUpperCase()} {e.nameFa}
              </i>
            </span>
            <span className='f1 ltr textRight'>{eye ? '***' : autoFormatter(e.balance)}</span>
            <span className='settingWallet'>
              {e.symbol !== 'tmn' && (
                <i className={`boxStatus bgwhite`} onClick={() => dispatch(centerDialog(<Trade data={e} />))}>
                  معامله
                </i>
              )}
              <i className={`boxStatus bgGreen`} onClick={() => dispatch(fullDrawer(<Deposit symbol={e.symbol} />))}>
                واریز
              </i>
              <i className={`boxStatus bgRed`} onClick={() => dispatch(fullDrawer(<Withdraw symbol={e.symbol} />))}>
                برداشت
              </i>
            </span>
          </div>,
        );
      });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return (
      <div className='openOrder'>
        <div>
          <span className='w120'>ارز</span>
          <span className='f1'>دارایی</span>
          <span>&nbsp;</span>
        </div>
        {rows}
      </div>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      const success = searchParams.get('success');
      if (success) {
        dispatch(fullDrawer(<Alert success={success} />));
      }
    }, 400);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header title={'کیف پول'} />
      <div className='whiteBg buyExpress'>
        <div className='container'>
          <div className='allAsset'>
            <div className='titleOfAsset'>
              <h3>دارایی کل</h3>
              <div className={`eye eyeAsset ${eye && 'active'}`} onClick={() => setEye(!eye)}></div>
              <span onClick={() => dispatch(fullDrawer(<History />))}>
                تاریخچه تراکنش ها<i className='rightArrow'></i>
              </span>
            </div>
            <div className='bodyOfAsset'>
              <h3>{eye ? '***' : autoFormatter(Object.values(balance).reduce((n, e) => n + e.value.tmn, 0))} تومان</h3>
            </div>
          </div>
          <div className='lineAsset'></div>
          <div className='allAsset'>
            <div className='titleOfAsset'>
              <h3>سود زیان روز گذشته</h3>
              {/* <span onClick={() => dispatch(fullDrawer(<Portfo />))}>
                پورتفو<i className='rightArrow'></i>
              </span> */}
            </div>
            <div className='bodyOfAsset'>
              <h3>{eye ? '***' : '0'} تومان</h3>
              <span className='boxStatus percent bgGreen'>{eye ? '***' : '45%'}</span>
            </div>
          </div>
          <div className='forceButton'>
            <div onClick={() => dispatch(fullDrawer(<Deposit />))}>واریز</div>
            <div onClick={() => dispatch(fullDrawer(<Withdraw />))}>برداشت</div>
          </div>
          <div className='hider'>
            <div className='rules' onClick={() => setSmall(!small)}>
              پنهان کردن موجودی های کوچک
              <span className={small ? 'active' : ''}></span>
            </div>
            <div className='searchIcon' onClick={() => setSearch(!search)}></div>
          </div>
          {search && <input type='text' className='ltr textRight searchIn' onChange={(e) => setText(e.target.value)} placeholder='جستجوی ارز' />}
        </div>
      </div>
      <div className='whiteBg buyExpress'>
        <div className='container'>{balanceRender()}</div>
      </div>
      <Bottom />
    </>
  );
};

export default Wallet;
