import PatchCode from 'components/Helper/PatchCode';
import config from 'config';
import useTimer from 'hooks/useTimer';
import { ICode } from 'interfaces/register';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Code = ({ mobile, setStep }: ICode) => {
  const [code, setCode] = useState<number[]>([]);
  const dispatch = useDispatch();
  const timer = useTimer();

  const confirmAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/auth/signup/confirm`).post({
      mobile: mobile.startsWith('0') ? mobile : '0' + mobile,
      code: code.join(''),
    });

    if (result.code === 200) {
      Cookies.set('pwaAccessToken', result.data.accessToken, { expires: 30 * 24 * 60 * 60, path: '/' });
      Cookies.set('pwaRefreshToken', result.data.refreshToken, { expires: 30 * 24 * 60 * 60, path: '/' });
      window.location.reload();
    } else {
      setCode([]);
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    timer.start();
  }, []);

  useEffect(() => {
    if (code.join('').match(/[0-9]{6}/)) {
      confirmAction();
    }
  }, [code]);

  return (
    <>
      <h4 className='confirmTitle'>تایید پیامک فعالسازی</h4>
      <div className='rules'>
        کد فعالسازی برای شماره <i>{mobile}</i> پیامک شد
      </div>
      <PatchCode code={code} setCode={setCode} />
      <div className='changeMobile'>
        <i>
          {!timer.isFinish ? (
            <>
              {timer.minute ? `${timer.minute} دقیقه و ` : ''}
              {timer.second} ثانیه
            </>
          ) : (
            <div
              className='active'
              onClick={() => {
                // startTimer();
              }}
            >
              ارسال مجدد کد
            </div>
          )}
        </i>
        <p onClick={() => setStep(1)}>ویرایش شماره</p>
      </div>
    </>
  );
};

export default Code;
