import Top from 'components/Layouts/Top';
import useUser from 'hooks/useUser';
import { useDispatch } from 'react-redux';
import { centerDialog } from 'services/reducer/dialog';
import { fullDrawer } from 'services/reducer/drawer';
import bank from 'utils/bank';
import NewCard from '../NewCard';
import View from './View';

const Cards = () => {
  const user = useUser();
  const dispatch = useDispatch();

  const ticketsRender = () => {
    const rows: JSX.Element[] = [];

    Object.values(user.cards).forEach((e) => {
      rows.push(
        <div onClick={() => dispatch(centerDialog(<View data={e} />))}>
          <span>
            <img src={bank.detect(e.number!)?.logo} />
          </span>
          <span>{bank.detect(e.number!)?.title}</span>
          {e.number && <span>{e.number.match(new RegExp('.{1,4}', 'g'))?.join('-')}</span>}
        </div>,
      );
    });

    if (rows.length === 0) {
      return <div className='noItem'>موردی یافت نشد</div>;
    }

    return (
      <div className='openOrder cardList'>
        <div>
          <span>#</span>
          <span>بانک</span>
          <span>شماره کارت</span>
        </div>
        {rows}
      </div>
    );
  };

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'کارت های بانکی'} />
        <div className='container inData'>
          <div className='addNew' onClick={() => dispatch(fullDrawer(<NewCard />))}>
            افزودن کارت بانکی
          </div>
          <div className='items itemsFull itemsTicket'>{ticketsRender()}</div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
