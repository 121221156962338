import { configureStore } from '@reduxjs/toolkit';
import alert from '../reducer/alert';
import dialog from '../reducer/dialog';
import drawer from '../reducer/drawer';
import global from '../reducer/global';
import loading from '../reducer/loading';
import ticker from '../reducer/ticker';
import user from '../reducer/user';

export default configureStore({
  reducer: {
    loading,
    global,
    alert: alert.a,
    dialog,
    user,
    ticker,
    drawer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
