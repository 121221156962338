import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideDrawer } from 'services/reducer/drawer';
import Code from './Code';
import Form from './Form';

const ForgetPassword = () => {
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState('');
  const dispatch = useDispatch();

  return (
    <div className='popup'>
      <div className='registerForm'>
        <div className='closePop' onClick={() => dispatch(hideDrawer())}></div>
        <div className='logo'></div>
        <div className='inForm'>
          {step === 1 ? <Form mobile={mobile} setMobile={setMobile} setStep={setStep} /> : <Code mobile={mobile} setStep={setStep} />}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
