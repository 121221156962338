export enum ETicker {
  close = 'close',
  low = 'low',
  high = 'high',
  change = 'change',
  volume = 'volume',
  value = 'value',
  buy = 'buy',
  sell = 'sell',
}

export enum EStatus {
  pending = 'pending',
  complete = 'complete',
  cancel = 'cancel',
  reject = 'reject',
  confirm = 'confirm',
  partial = 'partial',
  uphold = 'uphold',
  close = 'close',
  referral = 'referral',
  answer = 'answer',
}

export enum EColor {
  orange = 'Orange',
  gray = 'Gray',
  green = 'Green',
  red = 'Red',
  blue = 'Blue',
  light = 'Light',
  yellow = 'Yellow',
}

export enum EPair {
  tmn = 'tmn',
  usdt = 'usdt',
}

export enum ETransaction {
  buy = 'buy',
  sell = 'sell',
  withdraw = 'withdraw',
  deposit = 'deposit',
  receive = 'receive',
  send = 'send',
  cashout = 'cashout',
  create = 'create',
  use = 'use',
}

export enum EPairModel {
  coin = 'coin',
  currency = 'currency',
}
