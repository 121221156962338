import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import { ICoin, ICurrency, ILevel, IPair, ISetting, ISubject } from 'interfaces/global';
import gather from 'utils/gather';

const initialState = {
  subjects: [] as ISubject[],
  setting: {} as ISetting,
  pairs: {} as { [k: string]: IPair },
  levels: {} as { [k: string]: ILevel },
  currencyUnit: 'TMN',
  currencies: {} as { [k: string]: ICurrency },
  coins: {} as { [k: string]: ICoin },
};

const getCoins = createAsyncThunk('coins/fetchCoins', async () => {
  return await gather(`${config.data.api}/v1/coin`).get();
});

const getSetting = createAsyncThunk('setting/fetchSetting', async () => {
  return await gather(`${config.data.api}/v1/setting`).get();
});

const getLevels = createAsyncThunk('levels/fetchLevels', async () => {
  return await gather(`${config.data.api}/v1/level`).get();
});

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSetting.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.setting = action.payload.data;
        }
      })
      .addCase(getCoins.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: ICoin) => {
            state.coins[value.symbol] = value;
            state.coins[value.symbol].icon = `${config.data.api}/icons/${value.symbol.toUpperCase()}.png`;
          });
        }
      })
      .addCase(getLevels.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: ILevel) => {
            state.levels[value.type] = value;
          });
        }
      });
  },
});

export { getCoins, getLevels, getSetting };
export const {} = slice.actions;
export default slice.reducer;
