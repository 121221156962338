const faNums = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
const arNums = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
const enRegex = /[0-9]/g;
const faRegex = /[۰۱۲۳۴۵۶۷۸۹]/g;
const arRegex = /[٠١٢٣٤٥٦٧٨٩]/g;

const refreshPage = async () => {
  window.location.reload();
};

const numbersToEn = (str: string) => {
  return str.replace(faRegex, (char) => `${faNums.indexOf(char)}`).replace(arRegex, (char) => `${arNums.indexOf(char)}`);
};

const numbersToFa = (str: string) => {
  return str.replace(enRegex, (char) => `${faNums[Number(char)]}`).replace(arRegex, (char) => faNums[arNums.indexOf(char)]);
};

const dotted = (str: string, move: number = 12) => {
  return str.substr(0, move) + ' ... ' + str.substr(-move);
};

export { dotted, numbersToEn, numbersToFa, refreshPage };
