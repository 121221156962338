import config from 'config';
import { IDispatch } from 'interfaces/global';
import { IForm } from 'interfaces/identity';
import moment from 'jalali-moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import gather from 'utils/gather';

const Form = ({ setStep }: IForm) => {
  const [name, setName] = useState('');
  const [family, setFamily] = useState('');
  const [address, setAddress] = useState('');
  const [nationalCode, setNationalCode] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const dispatch = useDispatch<IDispatch>();

  const daysRender = (from: number, to: number) => {
    const rows: JSX.Element[] = [];
    for (let i = from; i <= to; i++) {
      rows.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return rows;
  };

  const saveAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/verify`, true).post({
      name: name,
      family: family,
      address: address,
      nationalCode: nationalCode,
      birthDate: moment.from(year + '-' + month + '-' + day, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD'),
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(getProfile());
      setStep(2);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  return (
    <>
      <div className='mainInput'>
        <p>نام</p>
        <div>
          <span className='name'></span>
          <input type='text' className='rtl' onChange={(e) => setName(e.target.value)} />
        </div>
      </div>
      <div className='mainInput'>
        <p>نام خانوادگی</p>
        <div>
          <span className='family'></span>
          <input type='text' className='rtl' onChange={(e) => setFamily(e.target.value)} />
        </div>
      </div>
      <div className='mainInput'>
        <p>کد ملی</p>
        <div>
          <span className='id'></span>
          <input type='number' inputMode='numeric' onChange={(e) => setNationalCode(e.target.value)} />
        </div>
      </div>
      <div className='mainInput birthdate'>
        <p>تاریخ تولد</p>
        <div>
          <span className='birthday'></span>
          <select onChange={(e) => setYear(e.target.value)}>
            <option>سال</option>
            {daysRender(1330, 1385)}
          </select>
          <select onChange={(e) => setMonth(e.target.value)}>
            <option>ماه</option>
            {daysRender(1, 12)}
          </select>
          <select onChange={(e) => setDay(e.target.value)}>
            <option>روز</option>
            {daysRender(1, 31)}
          </select>
        </div>
      </div>
      <div className='mainInput'>
        <p>آدرس</p>
        <div>
          <span className='address'></span>
          <input type='text' className='rtl' onChange={(e) => setAddress(e.target.value)} />
        </div>
      </div>
      <div className='spacer'></div>
      <div className='largeButton' onClick={() => saveAction()}>
        تایید و ادامه
      </div>
    </>
  );
};

export default Form;
