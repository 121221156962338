import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideDrawer } from 'services/reducer/drawer';
import Code from './Code';
import Form from './Form';

const Login = () => {
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState('');
  const [type, setType] = useState('');
  const dispatch = useDispatch();

  return (
    <div className='popup'>
      <div className='registerForm'>
        <div className='closePop' onClick={() => dispatch(hideDrawer())}></div>
        <div className='logo'></div>
        <div className='inForm'>
          {step === 1 ? (
            <Form mobile={mobile} setMobile={setMobile} setStep={setStep} setType={setType} />
          ) : (
            <Code mobile={mobile} setStep={setStep} type={type} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
