import Top from 'components/Layouts/Top';
import config from 'config';
import useUser from 'hooks/useUser';
import { IDispatch } from 'interfaces/global';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getCards } from 'services/reducer/user';
import bank from 'utils/bank';
import gather from 'utils/gather';

const space = '            ';

const NewCard = () => {
  const [card, setCard] = useState('');
  const { profile } = useUser();
  const dispatch = useDispatch<IDispatch>();

  const saveAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/card`, true).post({
      number: card,
    });

    if (result.code === 200) {
      await dispatch(getCards());
      dispatch(successAlert('درخواست با موفقیت ثبت شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'ثبت کارت جدید'} />
        <div className='container inData'>
          <div className='rules rulesNoFlex rulesCenter'>
            لطفا کارت بانکی خود مطابق با کد ملی <i>{profile.nationalCode}</i> وارد نمایید
          </div>
          <img src={bank.detect(card)?.logo || './svg/cardDown.svg'} className='bankLogo' />
          <form className='verifyCode'>
            <input
              className='ltr'
              type='text'
              onChange={(e) => setCard(e.target.value.replace(/\D/g, ''))}
              value={card && card.match(new RegExp('.{1,4}', 'g'))?.join(space)}
              placeholder={'----' + space + '----' + space + '----' + space + '----'}
            />
          </form>
          <div className='spacer'></div>
          <div className='largeButton' onClick={() => saveAction()}>
            ثبت کارت
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCard;
