const formatter = (price: number, max: number = 0, min: number = 0) => {
  return price.toLocaleString('en-US', {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
};

const autoFormatter = (price: number) => {
  let max = 0;

  if (price >= 50000) {
    max = 0;
  } else if (price >= 1000) {
    max = 2;
  } else if (price >= 100) {
    max = 3;
  } else if (price >= 10) {
    max = 4;
  } else if (price >= 1) {
    max = 5;
  } else {
    const demical = price.toString().split('.')[1];
    if (demical) {
      max = Number(demical.match(/^0*/)?.[0].length) + 3;
    }
  }

  return formatter(price, max, 0);
};

const getPercent = (v: number, d: number) => {
  return Number((v * d) / 100);
};

const getRandomNumber = (min: number, max: number, decimals: number) => {
  return parseFloat((Math.random() * (max - min) + min).toFixed(decimals));
};

const toFixed = (number: number, digits: number = 8) => {
  return Number(number.toFixed(digits));
};

const toFloor = (number: number, value: number = 8) => {
  const precision = Math.pow(10, value);
  return Math.floor(number * precision) / precision;
};

const toCeil = (number: number, value: number = 8) => {
  const precision = Math.pow(10, value);
  return Math.ceil(number * precision) / precision;
};

export { autoFormatter, formatter, getPercent, getRandomNumber, toCeil, toFixed, toFloor };
