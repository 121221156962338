import useGlobal from 'hooks/useGlobal';
import { IAsset } from 'interfaces/global';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hideDialog } from 'services/reducer/dialog';

const Trade = ({ data }: { data: IAsset }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const global = useGlobal();

  return (
    <div className='popup selectTrade'>
      <h6>
        {data.symbol} ({data.name})
        <img crossOrigin='anonymous' src={data.icon} />
      </h6>
      <div>
        <span
          onClick={() => {
            dispatch(hideDialog());
            navigate('/', { state: { default: Object.values(global.coins).find((e) => e.symbol === data.symbol) } });
          }}
        >
          مبادله فوری
        </span>
        <span
          onClick={() => {
            dispatch(hideDialog());
            navigate('/market', { state: { default: Object.values(global.coins).find((e) => e.symbol === data.symbol) } });
          }}
        >
          مبادله حرفه ای
        </span>
      </div>
    </div>
  );
};

export default Trade;
