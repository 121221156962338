import Top from 'components/Layouts/Top';
import config from 'config';
import useUser from 'hooks/useUser';
import { IDispatch } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import gather from 'utils/gather';
import GoogleAuth from '../GoogleAuth';

const Security = () => {
  const { profile } = useUser();
  const [first, setFirst] = useState(true);
  const [hideAsset, setHideAsset] = useState(profile.setting.hideAsset || false);
  const [twoStep, setTwoStep] = useState(profile.setting.twoStep || 'sms');
  const dispatch = useDispatch<IDispatch>();

  const checkGoogle = () => {
    if (profile.verify.gAuth) {
      setTwoStep('gAuth');
    } else {
      dispatch(fullDrawer(<GoogleAuth />));
    }
  };

  const saveAction = async () => {
    dispatch(showLoading());

    await gather(`${config.data.api}/v1/user/setting`, true).post({
      twoStep: twoStep,
      hideAsset: hideAsset,
    });

    await dispatch(getProfile());

    dispatch(hideLoading());
  };

  useEffect(() => {
    if (first) {
      setFirst(false);
      return;
    }

    saveAction();
  }, [hideAsset, twoStep]);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'امنیت'} />
        <div className='container inData inDataNo'>
          <h4 className='notesTitle'>شناسایی دوعاملی</h4>
          <div className='noteList'>
            <span>ورود به حساب و برداشت دارایی</span>
          </div>
          <div className='inSecurity'>
            <div className={`switcher ${twoStep === 'sms' && 'active'}`} onClick={() => setTwoStep('sms')}>
              پیامک<span></span>
            </div>
            <div className={`switcher switcherGa ${twoStep === 'gAuth' && 'active'}`} onClick={() => checkGoogle()}>
              گوگل آتنتیکیتور<span></span>
            </div>
          </div>
        </div>
        <div className='container inData inDataNo'>
          <h4 className='notesTitle'>تنظیمات</h4>
          <div className='inSecurity'>
            <div className={`switcher switcherEye ${hideAsset && 'active'}`} onClick={() => setHideAsset(!hideAsset)}>
              پنهان کردن دارایی‌ها<span></span>
            </div>
          </div>
        </div>
      </div>
      <div className='spacer'></div>
    </div>
  );
};

export default Security;
