import config from 'config';
import useUser from 'hooks/useUser';
import { IDispatch } from 'interfaces/global';
import { ICard } from 'interfaces/identity';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getCards } from 'services/reducer/user';
import bank from 'utils/bank';
import gather from 'utils/gather';

const Card = ({ setStep }: ICard) => {
  const [card, setCard] = useState('');
  const dispatch = useDispatch<IDispatch>();
  const { profile } = useUser();

  const space = '            ';

  const saveAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/card`, true).post({
      number: card,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(getCards());
      setStep(3);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  return (
    <>
      <div className='rules rulesNoFlex rulesCenter'>
        لطفا کارت بانکی خود مطابق با کد ملی <i>{profile.nationalCode}</i> وارد نمایید
      </div>
      <img src={bank.detect(card)?.logo || './svg/cardDown.svg'} className='bankLogo' />
      <form className='verifyCode'>
        <input
          className='ltr'
          type='text'
          onChange={(e) => setCard(e.target.value.replace(/\D/g, ''))}
          value={card && card.match(new RegExp('.{1,4}', 'g'))?.join(space)}
          placeholder={'----' + space + '----' + space + '----' + space + '----'}
        />
      </form>
      <div className='spacer'></div>
      <div className='largeButton' onClick={() => saveAction()}>
        تایید و ادامه
      </div>
    </>
  );
};

export default Card;
