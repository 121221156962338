import SelectBox from 'components/Helper/SelectBox';
import config from 'config';
import useGlobal from 'hooks/useGlobal';
import useUser from 'hooks/useUser';
import { ICard } from 'interfaces/global';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { fullDrawer, hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import bank from 'utils/bank';
import gather from 'utils/gather';
import { autoFormatter, toCeil, toFixed } from 'utils/numeral';
import NewCard from '../NewCard';

const Fiat = () => {
  const dispatch = useDispatch();
  const global = useGlobal();
  const user = useUser();
  const [amount, setAmount] = useState(0);
  const [card, setCard] = useState<ICard | null>(null);

  const saveAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/payment/cashout`, true).post({
      amount: amount,
      card: card?._id,
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(successAlert('درخواست با موفقیت ثبت شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  const max = toFixed((user.profile.limit.fiat.daily / user.profile.plane.level.withdraw.fiat.daily) * 100 || 0, 2);

  return (
    <>
      <div className='step stepDeposit'>
        <i>۱</i>
        <h3>انتخاب شماره کارت</h3>
        <section className='addCard' onClick={() => dispatch(fullDrawer(<NewCard />))}>
          افزودن کارت
        </section>
      </div>
      <SelectBox
        data={user.cards}
        search={false}
        value={
          card ? (
            <>
              <img src={bank.detect(card.number!)?.logo || './svg/cardDown.svg'} />
              <h3>{card.bank}</h3>
              <h2>{card.number!.match(new RegExp('.{1,4}', 'g'))?.join('-')}</h2>
            </>
          ) : (
            <>شماره کارت خود را انتخاب کنید</>
          )
        }
        template='card'
        setSelected={setCard}
      />
      <div className='step stepDeposit'>
        <i>۲</i>
        <h3>مقدار برداشت</h3>
        <section className='addCard addCardNo' onClick={() => setAmount(user.balance.fiat.free)}>
          موجودی: {autoFormatter(user.balance.fiat.free)} تومان
        </section>
      </div>
      <div className='mainInput'>
        <div>
          <NumericFormat
            inputMode='numeric'
            className='ltr'
            value={amount > 0 ? amount : ''}
            allowNegative={false}
            thousandSeparator={true}
            onValueChange={(e) => setAmount(Number(e.value))}
            placeholder='0.0'
          />
        </div>
      </div>
      <div className='listCashCode listCashTop'>
        <section>
          <div>
            <span>کارمزد برداشت</span>
            <span>{autoFormatter(toCeil(amount / global.setting.fiat.withdraw.step, 0) * global.setting.fiat.withdraw.fee)} تومان</span>
          </div>
          <div>
            <span>حداقل مقدار برداشت</span>
            <span>{autoFormatter(global.setting.fiat.withdraw.min)} تومان</span>
          </div>
        </section>
      </div>
      <div className='spacer'></div>
      <div className='largeButton largeButtonGreen' onClick={() => saveAction()}>
        درخواست برداشت
      </div>
      <div className='listCashCode listMax'>
        <section>
          <div>
            <span>سقف برداشت شما (روزانه):</span>
            <span>{autoFormatter(user.profile.plane.level.withdraw.fiat.daily)} تومان</span>
          </div>
          <div>
            <span>برداشت شده:</span>
            <span>{autoFormatter(user.profile.limit.fiat.daily)} تومان</span>
          </div>
        </section>
      </div>
      <div className='maxWithdraw'>
        <h4>{max}% سقف روزانه استفاده شده</h4>
        <section>
          <span style={{ width: max + '%' }}></span>
        </section>
      </div>
    </>
  );
};

export default Fiat;
