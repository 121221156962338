import { createSlice } from '@reduxjs/toolkit';
import { IDialog } from 'interfaces/reducer';

const initialState = {
  dialogs: [] as IDialog[],
};

const slice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    centerDialog: (state, actions) => {
      state.dialogs.push({
        open: true,
        content: actions.payload,
      });
    },
    hideDialog: (state) => {
      const index = state.dialogs.findLastIndex((e) => e.open);
      if (index >= 0) {
        state.dialogs[index <= 0 ? 0 : index].open = false;
      }
      return state;
    },
    showDialog: (state) => {
      state.dialogs[state.dialogs.length - 1].open = true;
      return state;
    },
  },
});

export const { centerDialog, hideDialog, showDialog } = slice.actions;

export default slice.reducer;
