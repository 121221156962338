import Tabs from 'components/Helper/Tabs';
import Top from 'components/Layouts/Top';
import { useEffect, useState } from 'react';
import Crypto from './Crypto';
import Fiat from './Fiat';

const tabTitles = {
  toman: 'تومان',
  crypto: 'ارز دیجیتال',
};

const Withdraw = ({ symbol }: { symbol?: string }) => {
  const [tab, setTab] = useState(tabTitles.toman);

  useEffect(() => {
    if (symbol) {
      if (symbol !== 'tmn') {
        setTab(tabTitles.crypto);
      }
    }
  }, []);

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'برداشت'} />
        <div className='container inData'>
          <div className='tabs tabsTop'>
            <Tabs titles={tabTitles} selected={tab} setSelected={setTab} />
          </div>
          {tab === tabTitles.toman ? <Fiat /> : <Crypto symbol={symbol} />}
        </div>
        <div className='container inData'>
          <h4 className='notesTitle'>نکات مهم</h4>
          <div className='noteList'>
            {tab === tabTitles.toman ? (
              <>
                <span>هنگام انتخاب کارت بانکی دقت کنید.</span>
                <span>
                  مقدار مبلغ درخواستی به <i>تومان</i> وارد نمایید.
                </span>
                <span>دقت کنید ، کارت بانکی که قصد ثبت در پروفایلتان دارید باید با کد ملی شما مطابقت داشته باشد.</span>
                <span>
                  جهت دریافت سریعتر تومان در خارج از سیکل پایا، از بانک های <i>ملی، ملت، سامان</i> استفاده نمائید
                </span>
                <span>
                  سیکل واریزی های پایا در ساعات <i>4:30، 11:30، 14:30 و 19:30</i> توسط شاپرک انجام خواهد شد
                </span>
                <span>
                  هنگام برداشت تومانی از حساب کاربری ، به مانده سقف روزانه/ ماهانه خود دقت کنید و در صورت نیاز به سقف بالاتر ، از منوی ارتقا حساب کاربری اقدام
                  نمایید.
                </span>
              </>
            ) : (
              <>
                <span>رمزارز و نوع شبکه را با دقت انتخاب کنید.</span>
                <span>آدرس کیف پول مقصد و مقدار رمزارز را با دقت وارد کنید.</span>
                <span>با توجه به نوع شبکه ، در صورتی که تگ/ MEMO الزامی است ، آن را به درستی وارد کنید.</span>
                <span>کارمزد شبکه از مقدار برداشت درخواستی شما کسر خواهد شد ، بنابراین در وارد کردن مقدار برداشت خود دقت کنید.</span>
                <span>
                  هنگام برداشت رمزارزی، به <i>سقف برداشت روزانه/ماهانه</i> دلاری خود دقت کنید
                </span>
              </>
            )}
          </div>
          <div className='spacer'></div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
