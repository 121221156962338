import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { hideDrawer } from 'services/reducer/drawer';

const Top = ({ title }: { title: string; }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.delete('success');
    searchParams.delete('hash');
    searchParams.delete('message');
    setSearchParams(searchParams);
  }, []);

  return (
    <div className='top'>
      <div className='container'>
        <div className='rightArrow rightArrowPad' onClick={() => dispatch(hideDrawer())}></div>
        <h1>{title}</h1>
        <div className='info'></div>
      </div>
    </div>
  );
};

export default Top;
