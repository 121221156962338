import { EStatus, ETransaction } from 'enums/global';

const statusTranslate = (status: EStatus) => {
  if (status === EStatus.cancel || status === EStatus.reject) {
    return 'لغو شد';
  } else if (status === EStatus.complete || status === EStatus.confirm) {
    return 'تکمیل';
  } else if (status === EStatus.partial) {
    return 'ناقص';
  } else if (status === EStatus.pending) {
    return 'در انتظار';
  } else if (status === EStatus.close) {
    return 'بسته';
  } else if (status === EStatus.referral) {
    return ' ارجاع شده';
  } else if (status === EStatus.answer) {
    return 'پاسخ داده شد';
  }
  return 'در انتظار';
};

const sideTranslate = (status: ETransaction) => {
  if (status === ETransaction.buy) {
    return 'خرید';
  } else if (status === ETransaction.deposit) {
    return 'واریز';
  } else if (status === ETransaction.sell) {
    return 'فروش';
  } else if (status === ETransaction.cashout) {
    return 'برداشت';
  } else if (status === ETransaction.use) {
    return 'فـروش';
  } else if (status === ETransaction.create) {
    return 'خریـد';
  }
  return '-';
};

const payTranslate = (pay: string) => {
  if (pay === 'gateway') {
    return 'درگاه شتابی';
  } else if (pay === 'code') {
    return 'شناسه واریز';
  } else if (pay === 'manual') {
    return 'سیستمی';
  }
  return '-';
};

const tradeTranslate = (trade: string) => {
  if (trade === 'otc') {
    return 'معامله فوری';
  } else if (trade === 'limit') {
    return 'معامله محدود';
  } else if (trade === 'market') {
    return 'معامله بازار';
  }
  return '-';
};

export { payTranslate, sideTranslate, statusTranslate, tradeTranslate };
