import Email from 'components/Modules/Email';
import GoogleAuth from 'components/Modules/GoogleAuth';
import Identity from 'components/Modules/Identity';
import useUser from 'hooks/useUser';
import { IDispatch } from 'interfaces/global';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fullDrawer } from 'services/reducer/drawer';
import { isUserLogin } from 'utils/user';
import LevelList from './LevelList';

const VerifyList = () => {
  const { profile } = useUser();
  const dispatch = useDispatch<IDispatch>();

  useEffect(() => {
    if (isUserLogin()) {
      setTimeout(() => {
        if (!profile.plane.level) {
          dispatch(fullDrawer(<Identity />));
        }
      }, 400);
    }
  }, []);

  return !isUserLogin() ? (
    <></>
  ) : (
    <div className='container'>
      <div className='alarms'>
        {profile.plane.level.index === 0 && (
          <div className='emailAlarm identityAlarm' onClick={() => dispatch(fullDrawer(<LevelList />))}>
            جهت استفاده از تمام خدمات، سطح کاربری خود را ارتقا دهید
          </div>
        )}
        {!profile.verify.email && (
          <div className='emailAlarm' onClick={() => dispatch(fullDrawer(<Email />))}>
            ایمیل شما تایید نشده لطفا نسبت به تایید اقدام کنید
          </div>
        )}
        {!profile.verify.gAuth && profile.plane.level && (
          <div className='emailAlarm gAlarm' onClick={() => dispatch(fullDrawer(<GoogleAuth />))}>
            جهت امنیت بیشتر، Google Authenticator را فعال نمایید
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyList;
