import config from 'config';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { fullDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import bank from 'utils/bank';
import gather from 'utils/gather';
import Level1 from '../Level1';

const data = {
  ayandeh: {
    iban: 'IR260620000000203443585001',
    number: '0203443585001',
    owner: 'تجارت الکترونیک ارسباران',
  },
  saman: {
    iban: 'IR280560080181003061335003',
    number: '801-810-3061335-3',
    owner: 'تجارت الکترونیک ارسباران',
  },
  mellat: {
    iban: 'IR760120020000008992439961',
    number: '8992439961',
    owner: 'ایوان رایان پیام',
  },
} as any;

const Code = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const user = useUser();

  const copy = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch(successAlert('کپی شد'));
  };

  const listRender = () => {
    const rows: JSX.Element[] = [];

    list.forEach((e: any, i) => {
      rows.push(
        <section key={i}>
          <div onClick={() => copy(data[e.bank].iban)}>
            <span>شماره شبا مقصد</span>
            <i></i>
            <span>{data[e.bank].iban}</span>
            <img crossOrigin='anonymous' src={bank.logo(e.bank)!} alt='' />
          </div>
          <div onClick={() => copy(data[e.bank].number)}>
            <span>شماره حساب مقصد</span>
            <i></i>
            <span>{data[e.bank].number}</span>
          </div>
          <div onClick={() => copy(e.code)}>
            <span>شناسه پرداخت شما</span>
            <i></i>
            <span>{e.code}</span>
          </div>
          <div>
            <span>نام صاحب حساب</span>
            <span>{data[e.bank].owner}</span>
          </div>
        </section>,
      );
    });

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/cashCode`, true).post();
    if (result.code === 200) {
      setList(result.data.map((e: any) => e.data).flat(1));
    }

    dispatch(hideLoading());
  };

  const saveAction = async () => {
    dispatch(showLoading());

    const result1 = await gather(`${config.data.api}/v1/payment/code`, true).post({
      gateway: 'vandar',
    });

    const result2 = await gather(`${config.data.api}/v1/payment/code`, true).post({
      gateway: 'jibit',
    });

    if (result1.code === 200 && result2.code === 200) {
      init();
    } else {
      dispatch(errorAlert(config.errors[result1?.message] || config.errors[99999]));
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {list.length === 0 ? (
        <>
          <div className='step stepDeposit'>
            <i>۱</i>
            <h3>ارتقای سطح کاربری</h3>
          </div>
          <p className='rules'>جهت دریافت شناسه واریز حداقل سطح کاربری ۲ می باشد</p>
          <div className='step stepDeposit'>
            <i>۲</i>
            <h3>درخواست شناسه</h3>
          </div>
          <p className='rules'>شناسه واریز فقط برای بانک های ملت، آینده و سامان فعال می باشد</p>
          <div className='spacer'></div>
          {user?.profile?.plane?.level?.index > 0 ? (
            <div className='largeButton' onClick={() => saveAction()}>
              درخواست صدور شناسه واریز
            </div>
          ) : (
            <div className='largeButton largeButtonGreen' onClick={() => dispatch(fullDrawer(<Level1 />))}>
              درخواست ارتقای سطح کاربری
            </div>
          )}
        </>
      ) : (
        <>
          <div className='listCashCode'>{listRender()}</div>
          <div className='spacer'></div>
        </>
      )}
    </>
  );
};

export default Code;
