import PatchCode from 'components/Helper/PatchCode';
import config from 'config';
import { IDispatch } from 'interfaces/global';
import { IForm } from 'interfaces/identity';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import gather from 'utils/gather';

const Form = ({ setStep }: IForm) => {
  const [code, setCode] = useState<number[]>([]);
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch<IDispatch>();

  const acceptAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/rules`, true).get();
    if (result.code === 200) {
      setCurrent(1);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  const checkAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/rules/confirm`, true).post({
      code: code.join(''),
    });

    if (result.code === 200) {
      await dispatch(getProfile());
      setStep(2);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    if (code.join('').match(/[0-9]{6}/)) {
      checkAction();
    }
  }, [code]);

  return (
    <>
      <div className='emailLogo smsLogo smsLogoNew'></div>
      <div className='rules rulesCenter'>
        جهت ارتقا سطح کاربری، یک کد تایید به شما پیامک خواهد شد.
        <br />
        با تایید این کد، شما محتوای پیامک را خواهید پذیرفت.
      </div>
      {current === 0 ? (
        <>
          <div className='largeButton' onClick={() => acceptAction()}>
            ارسال کد تایید
          </div>
        </>
      ) : (
        <>
          <div className='rules rulesCenter'>
            <i>کد ارسال شده را وارد نمایید</i>
          </div>
          <PatchCode code={code} setCode={setCode} />
          <div className='largeButton' onClick={() => checkAction()}>
            ثبت و تایید کد
          </div>
        </>
      )}
    </>
  );
};

export default Form;
