import { EColor, EStatus, ETransaction } from 'enums/global';

const statusColor = (status: EStatus) => {
  if (status === EStatus.cancel || status === EStatus.reject || status === EStatus.close) {
    return EColor.red;
  } else if (status === EStatus.complete || status === EStatus.confirm || status === EStatus.answer) {
    return EColor.green;
  } else if (status === EStatus.partial || status === EStatus.referral) {
    return EColor.blue;
  }
  return EColor.yellow;
};

const typeColor = (type: ETransaction) => {
  if (type === ETransaction.sell || type === ETransaction.cashout || type === ETransaction.use) {
    return EColor.red;
  } else if (type === ETransaction.buy || type === ETransaction.deposit || type === ETransaction.create) {
    return EColor.green;
  }
  return EColor.yellow;
};

const priceColor = (price: number) => {
  if (price > 0) {
    return EColor.green;
  } else if (price < 0) {
    return EColor.red;
  }
  return EColor.light;
};

export { priceColor, statusColor, typeColor };
