import config from 'config';
import { IDispatch } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import gather from 'utils/gather';

const Confirm = () => {
  const [retry, setRetry] = useState(true);
  const dispatch = useDispatch<IDispatch>();

  const saveAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/levelUp`, true).get();

    dispatch(hideLoading());

    if (result.code === 200) {
      dispatch(getProfile());
      setRetry(false);
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  useEffect(() => {
    saveAction();
  }, []);

  return (
    <>
      {retry ? (
        <div className='confirmData cancelData' onClick={() => saveAction()}>
          <span></span>
          درخواست تکمیل نشد، جهت تلاش مجدد اینجا کلیک نمایید
        </div>
      ) : (
        <>
          <div className='confirmData'>
            <span></span>
            <h2>تکمیل شد</h2>
            <h3>تبریک! شما هم اکنون می توانید از ویژگی های سطح دو استفاده نمایید</h3>
          </div>
        </>
      )}
      <div className='largeButton' onClick={() => dispatch(hideDrawer())}>
        بازگشت به داشبورد
      </div>
    </>
  );
};

export default Confirm;
