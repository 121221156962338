import { createSlice } from '@reduxjs/toolkit';
import { IAlert } from 'interfaces/reducer';
import { random } from 'lodash';

const initialState = {
  alerts: [] as IAlert[],
};

const slice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    successAlert: (state, actions) => {
      state.alerts.push({
        id: random(1000000, 9000000),
        open: false,
        text: actions.payload,
        severity: 'success',
      });
    },
    errorAlert: (state, actions) => {
      state.alerts.push({
        id: random(1000000, 9000000),
        open: false,
        text: actions.payload,
        severity: 'error',
      });
    },
    hideAlert: (state, actions) => {
      const index = state.alerts.map((item) => item.id).indexOf(actions.payload);
      state.alerts[index].open = false;
      return state;
    },
    showAlert: (state, actions) => {
      const index = state.alerts.map((item) => item.id).indexOf(actions.payload);
      state.alerts[index].open = true;
      return state;
    },
    removeAlert: (state, actions) => {
      const index = state.alerts.map((item) => item.id).indexOf(actions.payload);
      if (index > -1) {
        state.alerts.splice(index, 1);
      }
      return state;
    },
  },
});

export const { successAlert, errorAlert, hideAlert, removeAlert, showAlert } = slice.actions;

export default {
  a: slice.reducer,
  b: slice.actions.successAlert,
};
