const data = {
  pwa: process.env.REACT_APP_PWA!,
  api: process.env.REACT_APP_API!,
};

const digital = ['pm', 'pmv', 'wm'];

const errors: { [key: number]: string } = {
  404: 'درخواست شما یافت نشد',
  429: 'تعداد درخواست های شما از حد مجاز گذشته است',
  10001: 'این موبایل قبلا ثبت شده است',
  10002: 'کد صحیح نمی باشد',
  10003: 'موبایل یا رمز عبور اشتباه است',
  10004: 'فایل پیدا نشد',
  10005: 'فایل صحیح نمی باشد',
  10006: 'کاربر تایید نشده است',
  10007: 'پرداخت تایید نشده است',
  10008: 'کد ملی مطابق تاریخ تولد نمی باشد',
  10009: 'کد ملی مطابق موبایل نمی باشد',
  10010: 'ایمیل صحیح نمی باشد',
  10011: 'موبایل صحیح نمی باشد',
  10012: 'پسورد را به درستی وارد نمایید',
  10013: 'تکرار پسورد را به درستی وارد نمایید',
  10014: 'شماره کارت با کد ملی مطابق نمی باشد',
  10015: 'کارت صحیح نمی باشد',
  10016: 'کارت قبلا ثبت شده است',
  10017: 'مدارک ثبت نشده است',
  10018: 'کارت موجود نمی باشد',
  10019: 'نوع داده صحیح نمی باشد',
  10020: '',
  10021: 'نام صحیح نمی باشد',
  10022: '',
  10023: '',
  10024: '',
  10025: '',
  10026: '',
  10027: '',
  10028: '',
  10029: 'موجودی کافی نیست',
  10030: '',
  10031: 'در انتظار تایید ارتقای سطح کاربری',
  10032: 'سطح بعدی یافت نشد. با مدیریت تماس بگیرید',
  10033: '',
  10034: 'حداقل میزان برداشت رعایت نشده است',
  10035: 'حداقل میزان معامله رعایت نشده است',
  10036: 'کد معرف صحیح نمی باشد',
  10037: 'سطح کاربری شما پایین است',
  10038: 'کد صحیح نمی باشد',
  10039: 'حداقل میزان ساخت کد رعایت نشده است',
  10040: 'درخواست منقضی شده است',
  10041: 'دریافت کننده صحیح نمی باشد',
  10042: 'قیمت ثبت نشده است',
  10043: 'تایید قوانین پذیرفته نشده است',
  10048: 'سفارش شما با موفقیت تکمیل شد',
  10056: 'حداقل میزان واریز رعایت نشده است',
  10057: 'کارت خود را انتخاب کنید',
  99999: 'پیام ناشناخته! مجدد سعی نمایید',
};

export default {
  data,
  errors,
  digital,
};
