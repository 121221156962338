import Top from 'components/Layouts/Top';
import { useState } from 'react';
import Code from './Code';
import Download from './Download';
import Form from './Form';

const stepsTitles = ['دانلود اپلیکیشن', 'ثبت درخواست', 'تایید کد'];

const GoogleAuth = () => {
  const [step, setStep] = useState(1);

  const stepsRender = () => {
    const rows: React.ReactElement[] = [];

    stepsTitles.forEach((e, i) => {
      rows.push(
        <div key={i} className={i + 1 <= step ? 'active' : ''}>
          <span></span>
          {e}
        </div>,
        <b key={i + 10}></b>,
      );
    });

    return rows;
  };

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'ثبت Google Auth'} />
        <div className='allSteps'>{stepsRender()}</div>
        <div className='container inData inDataCenter'>{step === 1 ? <Download setStep={setStep} /> : step === 2 ? <Form setStep={setStep} /> : <Code />}</div>
      </div>
    </div>
  );
};

export default GoogleAuth;
