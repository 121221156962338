import useGlobal from 'hooks/useGlobal';
import moment from 'jalali-moment';
import { useDispatch } from 'react-redux';
import { successAlert } from 'services/reducer/alert';
import { statusColor, typeColor } from 'utils/color';
import { sideTranslate, statusTranslate } from 'utils/translate';

const Voucher = ({ data }: any) => {
  const global = useGlobal();
  const dispatch = useDispatch();

  const code = data.code.split('-');

  const copy = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch(successAlert('کپی شد'));
  };

  return (
    <div className='voucherData'>
      <img crossOrigin='anonymous' src={global.coins[data.coin.symbol].icon} />
      <h2>
        {data.coin.name} ({data.coin.symbol.toUpperCase()})
      </h2>
      <div className='listCashCode'>
        <section>
          <div>
            <span>نوع</span>
            <span className={`color${typeColor(data.type)}`}>{sideTranslate(data.type)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>وضعیت</span>
            <span className={`color${statusColor(data.status)}`}>{statusTranslate(data.status)}</span>
          </div>
        </section>
        <section>
          <div>
            <span>مبلغ</span>
            <span className='ltr'>
              {data.amount} {data.coin.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>کارمزد</span>
            <span className='ltr'>
              {data.fee} {data.coin.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div>
            <span>نهایی</span>
            <span className='ltr'>
              {data.final} {data.coin.symbol.toUpperCase()}
            </span>
          </div>
        </section>
        <section>
          <div onClick={() => copy(code[0])}>
            <span>کد ووچر (10 رقمی)</span>
            <i></i>
            <span>{code[0]}</span>
          </div>
        </section>
        <section>
          <div onClick={() => copy(code[1])}>
            <span>کد فعالسازی (16 رقمی)</span>
            <i></i>
            <span>{code[1]}</span>
          </div>
        </section>
        <section>
          <div>
            <span>تاریخ</span>
            <span className='ltr'>{moment(data.createdAt).locale('fa').format('YYYY-MM-DD , HH:mm:ss')}</span>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Voucher;
