import Top from 'components/Layouts/Top';
import config from 'config';
import { IDispatch } from 'interfaces/global';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const Level2 = () => {
  const dispatch = useDispatch<IDispatch>();

  const acceptAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/levelUp`, true).get();
    if (result.code === 200) {
      dispatch(successAlert('درخواست شما با موفقیت ثبت شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'ارتقا سطح حساب'} />
        <div className='container inData'>
          <div className='emailLogo smsLogo'></div>
          <div className='rules rulesCenter'>
            ارتقا به سطح کاربری بالاتر فقط با تایید مدیریت انجام می شود
            <br />
            در صورت تمایل به ارتقا به سطح بالاتر تایید کنید
          </div>
          <div className='spacer'></div>
          <div className='largeButton' onClick={() => acceptAction()}>
            تایید و ثبت درخواست
          </div>
        </div>
      </div>
    </div>
  );
};

export default Level2;
