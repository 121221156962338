import PatchCode from 'components/Helper/PatchCode';
import config from 'config';
import { IDispatch } from 'interfaces/global';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import { getProfile } from 'services/reducer/user';
import gather from 'utils/gather';

const Code = () => {
  const [code, setCode] = useState<number[]>([]);
  const dispatch = useDispatch<IDispatch>();

  const confirmAction = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/gAuth/confirm`, true).post({
      code: code.join(''),
    });

    dispatch(hideLoading());

    if (result.code === 200) {
      await dispatch(getProfile());
      dispatch(successAlert('فعال سازی با موفقیت انجام شد'));
      dispatch(hideDrawer());
    } else {
      setCode([]);
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }
  };

  useEffect(() => {
    if (code.join('').match(/[0-9]{6}/)) {
      confirmAction();
    }
  }, [code]);

  return (
    <>
      <div className='emailLogo gLogo'></div>
      <div className='rules rulesCenter rulesCenterNo'>
        جهت تایید صحت فرایند فعال‌سازی کد دوعاملی عدد 6 رقمی نمایش داده شده در اپلیکیشن <i>Google Authenticator</i> را وارد کنید.
      </div>
      <PatchCode code={code} setCode={setCode} />
      <div className='largeButton' onClick={() => confirmAction()}>
        تایید و ادامه
      </div>
    </>
  );
};

export default Code;
