import Top from 'components/Layouts/Top';
import config from 'config';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorAlert, successAlert } from 'services/reducer/alert';
import { hideDrawer } from 'services/reducer/drawer';
import { hideLoading, showLoading } from 'services/reducer/loading';
import gather from 'utils/gather';

const ChangePass = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [oldPasswordEye, setOldPasswordEye] = useState(false);
  const [passwordEye, setPasswordEye] = useState(false);
  const [repasswordEye, setRepasswordEye] = useState(false);
  const dispatch = useDispatch();

  const saveAction = async () => {
    if (password !== repassword) {
      dispatch(errorAlert('رمز عبور و تکرار آن مشابه نمی باشد'));
      return;
    }

    dispatch(showLoading());

    const result = await gather(`${config.data.api}/v1/user/password`, true).put({
      oldPassword: oldPassword,
      password: password,
      repassword: repassword,
    });

    if (result.code === 200) {
      dispatch(successAlert('درخواست با موفقیت ثبت شد'));
      dispatch(hideDrawer());
    } else {
      dispatch(errorAlert(config.errors[result?.message] || config.errors[99999]));
    }

    dispatch(hideLoading());
  };

  return (
    <div className='popup'>
      <div className='registerForm colorForm'>
        <Top title={'تغییر رمز عبور'} />
        <div className='container inData'>
          <div className='mainInput'>
            <p>رمز عبور قدیم</p>
            <div>
              <span className='password'></span>
              <input
                type={!oldPasswordEye ? 'password' : 'text'}
                autoComplete='one-time-code'
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <i className={`eye ${oldPasswordEye && 'active'}`} onClick={() => setOldPasswordEye(!passwordEye)}></i>
            </div>
          </div>
          <div className='mainInput'>
            <p>رمز عبور جدید</p>
            <div>
              <span className='password'></span>
              <input
                type={!passwordEye ? 'password' : 'text'}
                autoComplete='one-time-code'
                onChange={(e) => setPassword(e.target.value)}
              />
              <i className={`eye ${passwordEye && 'active'}`} onClick={() => setPasswordEye(!passwordEye)}></i>
            </div>
          </div>
          <div className='mainInput'>
            <p>تکرار رمز عبور جدید</p>
            <div>
              <span className='password'></span>
              <input
                type={!repasswordEye ? 'password' : 'text'}
                autoComplete='one-time-code'
                onChange={(e) => setRepassword(e.target.value)}
              />
              <i className={`eye ${repasswordEye && 'active'}`} onClick={() => setRepasswordEye(!repasswordEye)}></i>
            </div>
          </div>
          <div className='spacer'></div>
          <div className='largeButton' onClick={() => saveAction()}>
            ثبت درخواست
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePass;
